import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { getFormulaSearch } from "../shared/formula-helper";
import { AppModalProps } from "../models/modalprops";
import { useFormulaStore } from "../store/useFormulaStore";
import { useScanStore } from "../store/useScanStore";
import { FormulaHeader } from "./formula-header";
import { FormulaItem } from "./formula-interfaces";
import Newformula from "./new-formula";

interface FormulaModalProps extends AppModalProps {
  AddToScan: (formulaId: string) => void;
}

export const FormulaModal = ({
  open,
  onClose,
  AddToScan,
}: FormulaModalProps) => {
  const [selectedTab, setTab] = useState(0);
  const ScanEditingId = useScanStore((s) => s.ScanEditingId);
  const Formulas = useFormulaStore((s) => s.Formulas);
  const [getFomulas, setFormulas] = useState<FormulaItem[]>();

  const getListData = () => {
    var formulas = Object.values(Formulas);

    if (selectedTab === 1) {
      formulas = formulas.filter((f) => f.Type === "System");
    }
    if (selectedTab === 2) {
      formulas = formulas.filter((f) => f.Type === "User");
    }
    return formulas;
  };

  if (!getFomulas && Formulas) {
    setFormulas(getListData());
  }

  const handleSetSearch = (value: string) => {
    if (value.length > 0) {
      setTab(0);
      setFormulas(getFormulaSearch(Formulas, value.toLowerCase()));
    } else {
      setFormulas(Object.values(Formulas));
    }
  };

  const addToScan = (Id: string) => {
    if (ScanEditingId) {
      AddToScan(Id);
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"sm"}>
      <FormulaHeader setSearch={handleSetSearch} />
      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="icon label tabs example"
        >
          <Tab label="All" />
          <Tab label="System Provided" />
          <Tab label="User" />
          <Tab label="Add New Formula" />
        </Tabs>
        <Box>
          {selectedTab !== 3 && (
            <List dense>
              {getFomulas?.map((f: any) => {
                return (
                  <Tooltip
                    enterDelay={300}
                    placement="bottom-start"
                    key={f.Name}
                    title={f.Description}
                    disableInteractive
                  >
                    <ListItem disablePadding onClick={() => addToScan(f.Id)}>
                      <ListItemButton>
                        <ListItemText primary={f.Name} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          )}
          {selectedTab === 3 && (
            <Newformula addToScan={addToScan} onClose={onClose} />
          )}
        </Box>
      </DialogContent>
      {selectedTab !== 3 && (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
