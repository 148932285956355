import { PointData, StockData } from "../interfaces/PointTypes";
import { RoundTwo, formatNumber } from "./PriceHelper";

export interface IDataTypeHelper<T> {
  getLegend(item: T | T[]): string;
  getCalcValue(item: T): number;
  getFillStyle(item: T): string;
  getHighLow(items: T[] | T[][], startIndex: number, endIndex: number): HighLow;
}

export interface HighLow {
  high: number;
  low: number;
}

export class PointDataHelper implements IDataTypeHelper<PointData> {
  getLegend(item: PointData | PointData[]): string {
    if (Array.isArray(item)) {
      var res = "";
      for (var i = 0; i < item.length; i++) {
        res += `${i}:${RoundTwo(item[i].Val)} `;
      }
      return res.trimEnd();
    } else {
      return RoundTwo(item.Val).toString();
    }
  }

  getCalcValue(item: PointData): number {
    return item.Val;
  }

  getFillStyle(item: PointData): string {
    return "blue";
  }

  getHighLow(
    items: PointData[],
    startIndex: number,
    endIndex: number
  ): HighLow {
    const subArray = items.slice(startIndex, endIndex + 1);

    // Handle the case where items is a single array
    return {
      high: Math.max(...subArray.map((x) => x.Val)),
      low: Math.min(...subArray.map((x) => x.Val)),
    };
  }
}

export class BarDataHelper implements IDataTypeHelper<StockData> {
  getHighLow(
    items: StockData[],
    startIndex: number,
    endIndex: number
  ): HighLow {
    const subArray = items.slice(startIndex, endIndex + 1);
    return {
      high: Math.max(...subArray.map((x) => x.Volume)),
      low: Math.min(...subArray.map((x) => x.Volume)),
    };
  }

  getLegend(item: StockData): string {
    return ` v:${formatNumber(item.Volume)}`;
  }

  getCalcValue(item: StockData): number {
    return item.Volume;
  }

  getFillStyle(item: StockData): string {
    var color = "green";
    if (item.Close < item.Open) {
      color = "red";
    }

    return color;
  }
}

export class CandleDataHelper implements IDataTypeHelper<StockData> {
  getHighLow(
    items: StockData[],
    startIndex: number,
    endIndex: number
  ): HighLow {
    const subArray = items.slice(startIndex, endIndex + 1);

    return {
      high: Math.max(...subArray.map((x) => x.High)),
      low: Math.min(...subArray.map((x) => x.Low)),
    };
  }
  getLegend(item: StockData): string {
    return `l:${RoundTwo(item.Low)} h:${RoundTwo(item.High)} v:${formatNumber(
      item.Volume
    )}`;
  }

  getCalcValue(item: StockData): number {
    return item.Close;
  }

  getFillStyle(item: StockData): string {
    var color = "green";
    if (item.Close < item.Open) {
      color = "red";
    }

    return color;
  }
}
