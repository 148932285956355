import React, { ChangeEvent, useState } from "react";

import { Box, Button, DialogActions, TextField } from "@mui/material";

import { GetFormulaApi } from "../api/formula-api";
import ConfirmationDialog from "../shared/confirmation";
import { useFormulaStore } from "../store/useFormulaStore";
import { FormulaItem } from "./formula-interfaces";

interface NewFormulaProps {
  onClose: () => void;
  addToScan: (Id: string) => void;
}

const Newformula = ({ onClose, addToScan }: NewFormulaProps) => {
  const initialFieldValues: FormulaItem = {
    Id: null,
    Description: "",
    Formula: "",
    Name: "",
    Type: "User",
  };

  const [formValues, setFormValues] = useState<FormulaItem>(initialFieldValues);
  const [openConfirm, setOpenConfirm] = useState(false);
  const SetFormulas = useFormulaStore((s) => s.SetFormulas);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof FormulaItem
  ) => {
    const newValue = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  const formChanged = () => {
    return (
      initialFieldValues.Description != formValues.Description ||
      initialFieldValues.Name != formValues.Name ||
      initialFieldValues.Formula != formValues.Formula
    );
  };

  const validateInput = () => {
    console.warn("not implemented");
  };

  const handleSave = () => {
    validateInput();

    //todo move this to store
    GetFormulaApi()
      .createFormulaItem(formValues)
      .then((f) => {
        formValues.Id = f as string;

        //TODO why this?
        const formulas = useFormulaStore().Formulas;
        SetFormulas(formulas);

        addToScan(f as string);
        setFormValues(initialFieldValues);
        handleClose();
      });
  };

  const handleClose = () => {
    if (formChanged()) {
      setOpenConfirm(true); // Show the confirmation dialog
    } else {
      onClose(); // No unsaved changes, close the dialog
    }
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    onClose();
  };

  const handleConfirmCancel = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        onCancel={handleConfirmCancel}
        onConfirm={handleConfirmClose}
      />

      <Box
        component="form"
        m={2}
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
      >
        <form>
          <TextField
            id="new-formula-name"
            label="Formula Name"
            variant="outlined"
            size="small"
            required
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(e, "Name")
            }
            inputProps={{
              autoComplete: "off",
            }}
          />
          <TextField
            id="new-formula"
            label="Formula"
            size="small"
            required
            fullWidth
            multiline
            rows={2}
            placeholder="Enter formula"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(e, "Formula")
            }
            inputProps={{
              autoComplete: "off",
            }}
          />
          <TextField
            id="new-formula-desc"
            label="Description"
            size="small"
            fullWidth
            multiline
            rows={4}
            placeholder="Description"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(e, "Description")
            }
            inputProps={{
              autoComplete: "off",
            }}
          />
        </form>
      </Box>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default Newformula;
