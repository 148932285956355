import { create } from "zustand";
import { Formulas } from "../formula/formula-interfaces";

interface useFormulaStore {
  IsForumlaModalOpen: boolean;
  SetFormulaModalOpen: (open: boolean) => void;
  Formulas: Formulas;
  SetFormulas: (formulas: Formulas) => void;
  isSnackOpen: boolean;
  SetSnackOpen: (isOpen: boolean) => void;
  SnackMessage: string;
  SetSnackMessage: (message: string) => void;
}

export const useFormulaStore = create<useFormulaStore>((set, get) => ({
  isSnackOpen: false,
  SetSnackOpen: (isOpen: boolean) => set(() => ({ isSnackOpen: isOpen })),
  SnackMessage: "",
  SetSnackMessage: (message: string) => set(() => ({ SnackMessage: message })),
  IsForumlaModalOpen: false,
  SetFormulaModalOpen: (open: boolean) =>
    set(() => ({ IsForumlaModalOpen: open })),
  Formulas: {},
  SetFormulas: (formulas: Formulas) => {
    set(() => ({
      Formulas: formulas,
    }));
  },
}));
