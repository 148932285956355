export function createCanvas(parentDiv: HTMLDivElement): HTMLCanvasElement {
  const uniqueId = `uniqueId_${Math.random().toString(36).substr(2, 9)}`; // Generating a unique ID

  let canvas = createCanvasEle(parentDiv, uniqueId);
  canvas.addEventListener("mouseleave", () => {
    document.body.style.cursor = "default";
  });

  return canvas;
}

export function createCanvasEle(
  parentDiv: HTMLDivElement,
  id: string
): HTMLCanvasElement {
  // Create a new canvas elements
  const canvas = document.createElement("canvas");
  canvas.id = id;
  canvas.width = parentDiv.clientWidth;
  canvas.height = parentDiv.clientHeight;
  canvas.style.position = "absolute";
  parentDiv.appendChild(canvas);
  return canvas;
}
