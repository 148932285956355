import { NumberSize, Resizable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import React, { useEffect, useRef, useState } from "react";
import { ContextMenu, SymbolListMenu } from "../menus/";
import { useAppStore, useFlagStore } from "../store/";
import { SvgFlag } from "../svgs/flag";
import { Symbols } from "./symbol-list-interface";
import "./symbol-list.scss";
import { useShallow } from "zustand/react/shallow";

const columns = [
  { index: 0, disp: "Flag", val: "isFlagged", type: "svg" },
  { index: 1, disp: "Symbol", val: "ticker", type: "string" },
  { index: 2, disp: "Price", val: "price", type: "string" },
  { index: 3, disp: "Change", val: "change", type: "string" },
];

interface ColumnSizes {
  [key: number]: string;
}

export type SortOrder = "asc" | "desc";

const PriceComponent = (symbol: string) => <>2.75</>;
const StringComponent = (val: any) => {
  return <>{val.val}</>;
};

const componentMap: { [key: string]: React.ComponentType<any> } = {
  string: StringComponent,
  svg: SvgFlag,
  price: PriceComponent,
};

interface SymbolProps {
  parentId: string;
  items: Symbols[];
  isActive: boolean;
  type: "scan" | "watchList";
}

interface SortState {
  column: string;
  order: SortOrder;
}

export const SymbolListComponent = ({
  parentId,
  items,
  isActive = false,
  type = "scan",
}: SymbolProps) => {
  const [SetActiveSymbol, ActiveSymbol] = useAppStore(
    useShallow((s) => [s.SetActiveSymbol, s.ActiveSymbol])
  );
  const [FlaggedTickers, IsTickerFlagged, AddFlag, RemoveFlag, ToggleFlag] =
    useFlagStore(
      useShallow((s) => [
        s.FlaggedTickers,
        s.IsTickerFlagged,
        s.AddFlag,
        s.RemoveFlag,
        s.ToggleFlag,
      ])
    );

  const [itemsState, setItemsState] = useState<any[]>([]);
  const [columnSizes, setColumnSizes] = useState<ColumnSizes>({
    1: "50px",
    2: "50px",
  });
  const [index, setIndex] = useState(-1);
  const [sortState, setSortState] = useState<SortState>({
    column: "ticker",
    order: "desc",
  });
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [selectedTicker, setSelectedTicker] = useState<string>("");

  const listRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    var itemsTransformed: any[] = items?.map((f) => {
      return {
        ...f,
        isFlagged: IsTickerFlagged(f.ticker),
      };
    });
    setItemsState(itemsTransformed);
  }, [items, FlaggedTickers]);

  useEffect(() => {
    if (itemsState.length == 0) return;
    var _idx = index > itemsState.length - 1 ? 0 : index;
    //todo why do we end up with this... seems like this shoudl be rewritten.
    if (_idx === -1) return;
    if (itemsState.length > _idx) {
      SetActiveSymbol(itemsState[_idx].ticker);
      scrollSelectedRowIntoView();
    }
  }, [index]);

  const scrollSelectedRowIntoView = () => {
    if (index !== -1 && containerRef.current) {
      const selectedRow = containerRef.current.querySelector(
        //need to skip header <TH>
        `tr:nth-child(${index + 2})`
      );
      if (selectedRow) {
        // selectedRow.scrollIntoView({
        //   behavior: "smooth",
        //   block: "center",
        //   // inline: "nearest",
        // });
      }
    }
  };

  const updateActiveIndex = (_items: any[]) => {
    var idx = _items?.findIndex((f) => f.ticker === ActiveSymbol);
    setIndex(idx);
  };

  useEffect(() => {
    updateActiveIndex(itemsState);
  }, [ActiveSymbol]);

  const checkForSpacebar = (e: KeyboardEvent) => {
    if (e.code === "Space") {
      e.preventDefault();
      setIndex((prev) => {
        const newIndex = prev + 1;
        return newIndex;
      });
    }
  };

  useEffect(() => {
    isActive && document.addEventListener("keydown", checkForSpacebar);

    return () => {
      document.removeEventListener("keydown", checkForSpacebar);
    };
  }, [isActive]);

  if (!itemsState) {
    return;
  }

  const handleRowRightClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    ticker: string
  ) => {
    event.preventDefault(); // Prevent default browser context menu
    const listRect = listRef.current?.getBoundingClientRect(); // Get the bounding rectangle of the list container
    const container = findClosestContainer(
      event.target as HTMLElement,
      "scrollable-container"
    ); // Find the closest scrollable container
    if (listRect && container) {
      const offsetX = event.clientX;
      const offsetY = event.clientY;
      setContextMenuPosition({ x: offsetX, y: offsetY });
      setShowContextMenu(true);
      setSelectedTicker(ticker);
    }
  };

  const findClosestContainer = (
    element: HTMLElement,
    containerId: string
  ): HTMLElement | null => {
    let currentElement: HTMLElement | null = element;
    while (currentElement && currentElement !== document.body) {
      if (currentElement.id === containerId) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
    }
    return null;
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
  };

  const handleResizeStop =
    (column: number) =>
    (
      event: MouseEvent | TouchEvent,
      direction: Direction,
      elementRef: HTMLElement,
      delta: NumberSize
    ) => {
      setColumnSizes({
        ...columnSizes,
        [column]: elementRef.style.width,
      });
    };

  const flagList = () => {
    //TODO does this cause a lot of renders?
    //better way?
    itemsState.forEach((item) => {
      AddFlag(item.ticker);
    });
  };

  const unFlagList = () => {
    itemsState.forEach((item) => {
      RemoveFlag(item.ticker);
    });
  };

  function sortByPropertyIndex(array: any[], sort: SortState) {
    const sortted = array.slice().sort((a, b) => {
      const aValue = a[sort.column];
      const bValue = b[sort.column];

      const result =
        typeof aValue === "number" && typeof bValue === "number"
          ? aValue - bValue
          : String(aValue).localeCompare(String(bValue));

      return sort.order === "asc" ? result : -result;
    });
    return sortted;
  }

  const handleSort = (columnName: string) => {
    var _sortState = sortState;
    if (_sortState.column === columnName) {
      _sortState.order = _sortState.order === "asc" ? "desc" : "asc";
    }

    _sortState.column = columnName;
    var sorted = sortByPropertyIndex(itemsState, _sortState);

    setSortState(_sortState);
    updateActiveIndex(sorted);
    setItemsState(sorted);
  };

  // Function to handle mouse leave on myDiv
  return (
    <div ref={listRef} className={showContextMenu ? "active-list" : ""}>
      <table className="ticker-list" ref={containerRef}>
        <thead>
          <tr>
            {columns.map((f, index) => {
              return (
                <th
                  key={`wl${f.index}`}
                  onClick={() => handleSort(f.val)}
                  style={{ cursor: "pointer" }}
                >
                  {
                    <Resizable
                      size={{ width: columnSizes[f.index], height: "auto" }}
                      onResizeStop={handleResizeStop(f.index)}
                      enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                      }}
                    >
                      {f.disp}
                    </Resizable>
                  }
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {itemsState.map((item, idx) => {
            const isActiveSymbol = item["ticker"] === ActiveSymbol;
            return (
              <tr
                key={`wl${idx}`}
                className={`${isActiveSymbol ? "symbol-active" : ""}`}
              >
                {columns.map((column, index) => {
                  const ComponentType = componentMap[column.type];
                  const val = (item as any)[column.val];
                  const keyId = `${idx}_${index}`;

                  var handleClick: (val: string) => void = () => {};
                  var _class = "";
                  var props = {};
                  var handleContextMenu: (
                    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
                    ticker: string
                  ) => void = () => {};

                  if (column.val === "ticker") {
                    _class = "ticker-column";
                    handleClick = (val) => SetActiveSymbol(val);
                    handleContextMenu = (event) =>
                      handleRowRightClick(event, val);
                  } else if (column.val === "isFlagged") {
                    handleClick = () => ToggleFlag(item["ticker"]);
                    _class = "flag-column";
                    props = { ...props, isFlagged: val };
                  }

                  return (
                    <td
                      className={_class}
                      onClick={() => handleClick(val)}
                      key={keyId}
                      onContextMenu={(e) => handleContextMenu(e, val)}
                    >
                      {<ComponentType {...props} {...{ val }} />}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ContextMenu
        open={showContextMenu}
        position={contextMenuPosition}
        onClose={closeContextMenu}
      >
        <SymbolListMenu
          parentId={parentId}
          onClose={closeContextMenu}
          ticker={selectedTicker}
          onFlagList={flagList}
          onUnFlagList={unFlagList}
          type="watchList"
        />
      </ContextMenu>
    </div>
  );
};
