import React, { useState, FormEvent } from "react";
import axios, { axios as baseAxios } from "../api/axios-wrapper";
import { Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { AuthModal } from "./authModal";
import { AuthResult } from "./authResult";

interface ForgotPasswordRequest {
  email: string;
}

const apiUrl = "auth/forgotpassword";

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string[] | null>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(apiUrl, {
        email,
      } as ForgotPasswordRequest);
      setMessage("Password reset link has been sent to your email.");
      setError([]);
    } catch (err) {
      if (baseAxios.isAxiosError(err) && err.response?.status === 400) {
        // Map the error response to extract error descriptions
        const errorMessages = err.response.data.map(
          (error: { description: string }) => error.description
        );

        // Set the error state to display the error messages
        setError(errorMessages);
      } else {
        setError(["An error occurred. Please try again later."]);
      }
    }
  };

  return (
    <AuthModal>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography variant="h5">Forgot Password</Typography>
          <TextField
            label="Email"
            type="email"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            startIcon={<SendIcon />}
          >
            Send Reset Link
          </Button>
          <AuthResult error={error} success={message} />
        </div>
      </form>
    </AuthModal>
  );
};
