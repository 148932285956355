import { generateRandomDecimal } from "../helpers/MathHelper";
import { PointData, StockData } from "../interfaces/PointTypes";

export function SixtyData(): StockData[] {
  return [
    {
      Date: new Date("11/17/2023 10:00:00 AM"),
      Open: 230.43539428710938,
      High: 234.88999938964844,
      Low: 229.17999267578125,
      Close: 234.58999633789062,
      Volume: 30634349,
    },
    {
      Date: new Date("11/17/2023 11:00:00 AM"),
      Open: 234.60000610351562,
      High: 236.3300018310547,
      Low: 233.41000366210938,
      Close: 235.23069763183594,
      Volume: 22614046,
    },
    {
      Date: new Date("11/17/2023 12:00:00 PM"),
      Open: 235.22000122070312,
      High: 236.48899841308594,
      Low: 234.25,
      Close: 236.27000427246094,
      Volume: 14007628,
    },
    {
      Date: new Date("11/17/2023 1:00:00 PM"),
      Open: 236.27499389648438,
      High: 237.38999938964844,
      Low: 235.08999633789062,
      Close: 235.90020751953125,
      Volume: 14518323,
    },
    {
      Date: new Date("11/17/2023 2:00:00 PM"),
      Open: 235.90499877929688,
      High: 236.16000366210938,
      Low: 233.4499969482422,
      Close: 234.65899658203125,
      Volume: 15265911,
    },
    {
      Date: new Date("11/17/2023 3:00:00 PM"),
      Open: 234.65699768066406,
      High: 235.35000610351562,
      Low: 234.10000610351562,
      Close: 234.47999572753906,
      Volume: 14423696,
    },
    {
      Date: new Date("11/17/2023 4:00:00 PM"),
      Open: 234.35,
      High: 234.49,
      Low: 233.44,
      Close: 233.85,
      Volume: 1497958,
    },
  ];
}

export function multiLineData(): PointData[][] {
  var hardcodedData = genLineMinute(400);
  // Generate second set of pointData based on a multiple of the existing values
  const multipliedData: PointData[] = hardcodedData.map((data) => {
    return {
      Val: data.Val * generateRandomDecimal(0.9, 1), // Multiply Val by 2
      Date: new Date(data.Date.getTime()), // Clone Date object
      Volume: data.Volume * 1.5, // Multiply Volume by 1.5
    };
  });

  // Return an array containing both sets of data
  return [hardcodedData, multipliedData];
}

export function getLineDaily(): PointData[] {
  const hardcodedData: PointData[] = [];

  // Start date
  let startDate = new Date("2023-01-01");

  // Initial values
  let val = 10;
  let volume = 100;

  // Loop to generate 400 times
  for (let i = 0; i < 400; i++) {
    hardcodedData.push({ Val: val, Date: new Date(startDate), Volume: volume });

    // Update values for the next iteration
    val += 5 * Math.random();
    volume += 5;
    startDate.setDate(startDate.getDate() + 1);
  }
  return hardcodedData;
}

export function genLineMinute(numPoints: number): PointData[] {
  const startDate = new Date("2023-01-01T09:30:00");
  const hardcodedData: PointData[] = [];

  for (let i = 0; i < numPoints; i++) {
    const currentDate = new Date(startDate);
    currentDate.setMinutes(currentDate.getMinutes() + i);

    const dataPoint: PointData = {
      Val: i * 5 + 10, // Example value, adjust as needed
      Date: currentDate,
      Volume: Math.floor(Math.random() * 50) + 90, // Random volume for demonstration
    };

    hardcodedData.push(dataPoint);
  }

  return hardcodedData;
}

export default function data(): StockData[] {
  return [
    {
      Open: 648.11,
      High: 648.5,
      Close: 648,
      Low: 647.5,
      Volume: 83025,
      Date: new Date("03/19/2021 12:10:00"),
    },
    {
      Open: 649.65,
      High: 649.81,
      Close: 648.28,
      Low: 647.8211,
      Volume: 104516,
      Date: new Date("03/19/2021 12:09:00"),
    },
    {
      Open: 649.54,
      High: 650.4999,
      Close: 649.8565,
      Low: 649.365,
      Volume: 46379,
      Date: new Date("03/19/2021 12:08:00"),
    },
    {
      Open: 650.3035,
      High: 650.47,
      Close: 649.27,
      Low: 648.93,
      Volume: 53079,
      Date: new Date("03/19/2021 12:07:00"),
    },
    {
      Open: 649.59,
      High: 650.3986,
      Close: 650.3986,
      Low: 649.28,
      Volume: 34545,
      Date: new Date("03/19/2021 12:06:00"),
    },
    {
      Open: 649.848,
      High: 650.07,
      Close: 649.59,
      Low: 649.2316,
      Volume: 45845,
      Date: new Date("03/19/2021 12:05:00"),
    },
    {
      Open: 650.53,
      High: 650.7599,
      Close: 649.71,
      Low: 649.7,
      Volume: 62729,
      Date: new Date("03/19/2021 12:04:00"),
    },
    {
      Open: 650.63,
      High: 651.1,
      Close: 650.39,
      Low: 650.244,
      Volume: 28833,
      Date: new Date("03/19/2021 12:03:00"),
    },
    {
      Open: 651.5322,
      High: 651.77,
      Close: 650.71,
      Low: 649.55,
      Volume: 91897,
      Date: new Date("03/19/2021 12:02:00"),
    },
    {
      Open: 648.8701,
      High: 651.8,
      Close: 651.54,
      Low: 648.87,
      Volume: 81873,
      Date: new Date("03/19/2021 12:01:00"),
    },
    {
      Open: 648.73,
      High: 649.864,
      Close: 648.7038,
      Low: 648.64,
      Volume: 43156,
      Date: new Date("03/19/2021 12:00:00"),
    },
    {
      Open: 650.43,
      High: 650.5699,
      Close: 648.53,
      Low: 648.4236,
      Volume: 49339,
      Date: new Date("03/19/2021 11:59:00"),
    },
    {
      Open: 649.5483,
      High: 651.36,
      Close: 650.2777,
      Low: 649.3701,
      Volume: 57499,
      Date: new Date("03/19/2021 11:58:00"),
    },
    {
      Open: 650.13,
      High: 651.07,
      Close: 649.49,
      Low: 648.938,
      Volume: 103631,
      Date: new Date("03/19/2021 11:57:00"),
    },
    {
      Open: 650.58,
      High: 651,
      Close: 650.185,
      Low: 649.79,
      Volume: 79381,
      Date: new Date("03/19/2021 11:56:00"),
    },
    {
      Open: 648.4817,
      High: 651.342,
      Close: 650.7509,
      Low: 648.38,
      Volume: 166982,
      Date: new Date("03/19/2021 11:55:00"),
    },
    {
      Open: 647.18,
      High: 648.53,
      Close: 648.4916,
      Low: 646.87,
      Volume: 93691,
      Date: new Date("03/19/2021 11:54:00"),
    },
    {
      Open: 647.33,
      High: 647.66,
      Close: 647.1442,
      Low: 645.8001,
      Volume: 97210,
      Date: new Date("03/19/2021 11:53:00"),
    },
    {
      Open: 648.435,
      High: 648.719,
      Close: 647.35,
      Low: 647.24,
      Volume: 77652,
      Date: new Date("03/19/2021 11:52:00"),
    },
    {
      Open: 649.49,
      High: 649.49,
      Close: 648.5,
      Low: 648.07,
      Volume: 76306,
      Date: new Date("03/19/2021 11:51:00"),
    },
    {
      Open: 649.5123,
      High: 650,
      Close: 649.6278,
      Low: 648.71,
      Volume: 55270,
      Date: new Date("03/19/2021 11:50:00"),
    },
    {
      Open: 649.01,
      High: 650.35,
      Close: 649.68,
      Low: 648.55,
      Volume: 134687,
      Date: new Date("03/19/2021 11:49:00"),
    },
    {
      Open: 648.43,
      High: 649.49,
      Close: 649.095,
      Low: 648.1541,
      Volume: 84959,
      Date: new Date("03/19/2021 11:48:00"),
    },
    {
      Open: 645.37,
      High: 649.2,
      Close: 648.352,
      Low: 645.33,
      Volume: 138502,
      Date: new Date("03/19/2021 11:47:00"),
    },
    {
      Open: 645.75,
      High: 646.1,
      Close: 645.065,
      Low: 645,
      Volume: 28442,
      Date: new Date("03/19/2021 11:46:00"),
    },
    {
      Open: 645.05,
      High: 645.7682,
      Close: 645.5,
      Low: 644.56,
      Volume: 51187,
      Date: new Date("03/19/2021 11:45:00"),
    },
    {
      Open: 645.42,
      High: 646.62,
      Close: 645.06,
      Low: 645.06,
      Volume: 68770,
      Date: new Date("03/19/2021 11:44:00"),
    },
    {
      Open: 644.36,
      High: 645.98,
      Close: 645.3217,
      Low: 644.36,
      Volume: 47965,
      Date: new Date("03/19/2021 11:43:00"),
    },
    {
      Open: 644.86,
      High: 645.5333,
      Close: 644.55,
      Low: 644.18,
      Volume: 53262,
      Date: new Date("03/19/2021 11:42:00"),
    },
    {
      Open: 646.1601,
      High: 646.58,
      Close: 644.4,
      Low: 644.4,
      Volume: 86074,
      Date: new Date("03/19/2021 11:41:00"),
    },
    {
      Open: 644.87,
      High: 646.31,
      Close: 646.1757,
      Low: 644.56,
      Volume: 50265,
      Date: new Date("03/19/2021 11:40:00"),
    },
    {
      Open: 645.52,
      High: 646.0406,
      Close: 644.715,
      Low: 644.591,
      Volume: 45204,
      Date: new Date("03/19/2021 11:39:00"),
    },
    {
      Open: 644.7,
      High: 645.8899,
      Close: 645.5,
      Low: 644.67,
      Volume: 60931,
      Date: new Date("03/19/2021 11:38:00"),
    },
    {
      Open: 644.4,
      High: 644.7399,
      Close: 644.4301,
      Low: 643.6114,
      Volume: 49822,
      Date: new Date("03/19/2021 11:37:00"),
    },
    {
      Open: 645.3,
      High: 646.08,
      Close: 644.502,
      Low: 644.36,
      Volume: 65813,
      Date: new Date("03/19/2021 11:36:00"),
    },
    {
      Open: 644.5,
      High: 645.6,
      Close: 645.07,
      Low: 644.2357,
      Volume: 76363,
      Date: new Date("03/19/2021 11:35:00"),
    },
    {
      Open: 644.15,
      High: 645.2745,
      Close: 644.455,
      Low: 643.65,
      Volume: 72550,
      Date: new Date("03/19/2021 11:34:00"),
    },
    {
      Open: 642.3208,
      High: 645.3268,
      Close: 644.5,
      Low: 642.29,
      Volume: 111653,
      Date: new Date("03/19/2021 11:33:00"),
    },
    {
      Open: 642.8,
      High: 643.7,
      Close: 642.6099,
      Low: 642.29,
      Volume: 65996,
      Date: new Date("03/19/2021 11:32:00"),
    },
    {
      Open: 644.0134,
      High: 644.08,
      Close: 643.08,
      Low: 642.8,
      Volume: 63284,
      Date: new Date("03/19/2021 11:31:00"),
    },
    {
      Open: 641.52,
      High: 644.18,
      Close: 644.1179,
      Low: 641.52,
      Volume: 118994,
      Date: new Date("03/19/2021 11:30:00"),
    },
    {
      Open: 641.88,
      High: 641.9299,
      Close: 641.335,
      Low: 640.2706,
      Volume: 64604,
      Date: new Date("03/19/2021 11:29:00"),
    },
    {
      Open: 641.4,
      High: 642.62,
      Close: 641.8,
      Low: 641.11,
      Volume: 91898,
      Date: new Date("03/19/2021 11:28:00"),
    },
    {
      Open: 639.54,
      High: 642,
      Close: 641.285,
      Low: 639.32,
      Volume: 139942,
      Date: new Date("03/19/2021 11:27:00"),
    },
    {
      Open: 642.52,
      High: 642.845,
      Close: 639.57,
      Low: 633.75,
      Volume: 524243,
      Date: new Date("03/19/2021 11:26:00"),
    },
    {
      Open: 643.92,
      High: 644.01,
      Close: 642.635,
      Low: 641.85,
      Volume: 89372,
      Date: new Date("03/19/2021 11:25:00"),
    },
    {
      Open: 643.32,
      High: 644.5,
      Close: 644.1,
      Low: 643.04,
      Volume: 63977,
      Date: new Date("03/19/2021 11:24:00"),
    },
    {
      Open: 643.95,
      High: 644.4799,
      Close: 643.51,
      Low: 642.5601,
      Volume: 100599,
      Date: new Date("03/19/2021 11:23:00"),
    },
    {
      Open: 645.73,
      High: 645.895,
      Close: 643.7153,
      Low: 643.68,
      Volume: 79187,
      Date: new Date("03/19/2021 11:22:00"),
    },
    {
      Open: 645.39,
      High: 645.99,
      Close: 645.745,
      Low: 644.03,
      Volume: 101520,
      Date: new Date("03/19/2021 11:21:00"),
    },
    {
      Open: 646.53,
      High: 646.6782,
      Close: 645.42,
      Low: 645.42,
      Volume: 82129,
      Date: new Date("03/19/2021 11:20:00"),
    },
    {
      Open: 646.61,
      High: 647.64,
      Close: 646.445,
      Low: 645.76,
      Volume: 47686,
      Date: new Date("03/19/2021 11:19:00"),
    },
    {
      Open: 646.59,
      High: 648.76,
      Close: 646.86,
      Low: 646.55,
      Volume: 90187,
      Date: new Date("03/19/2021 11:18:00"),
    },
    {
      Open: 648.003,
      High: 648.666,
      Close: 646.5801,
      Low: 646.3,
      Volume: 86142,
      Date: new Date("03/19/2021 11:17:00"),
    },
    {
      Open: 647.56,
      High: 649,
      Close: 647.73,
      Low: 647.56,
      Volume: 115482,
      Date: new Date("03/19/2021 11:16:00"),
    },
    {
      Open: 646.18,
      High: 647.559,
      Close: 647.5004,
      Low: 646.12,
      Volume: 84651,
      Date: new Date("03/19/2021 11:15:00"),
    },
    {
      Open: 646.42,
      High: 647,
      Close: 646,
      Low: 645.35,
      Volume: 68860,
      Date: new Date("03/19/2021 11:14:00"),
    },
    {
      Open: 647.635,
      High: 648.0099,
      Close: 646.34,
      Low: 645.27,
      Volume: 155007,
      Date: new Date("03/19/2021 11:13:00"),
    },
    {
      Open: 648.23,
      High: 649.41,
      Close: 647.67,
      Low: 647.6053,
      Volume: 123483,
      Date: new Date("03/19/2021 11:12:00"),
    },
    {
      Open: 648.4,
      High: 648.89,
      Close: 648.04,
      Low: 646.21,
      Volume: 131039,
      Date: new Date("03/19/2021 11:11:00"),
    },
    {
      Open: 647.86,
      High: 649.32,
      Close: 648.161,
      Low: 647.5,
      Volume: 80597,
      Date: new Date("03/19/2021 11:10:00"),
    },
    {
      Open: 649.3999,
      High: 649.94,
      Close: 647.989,
      Low: 647.39,
      Volume: 139317,
      Date: new Date("03/19/2021 11:09:00"),
    },
    {
      Open: 650.2242,
      High: 650.3508,
      Close: 648.94,
      Low: 647.62,
      Volume: 136918,
      Date: new Date("03/19/2021 11:08:00"),
    },
    {
      Open: 649.135,
      High: 650.58,
      Close: 650.37,
      Low: 649.135,
      Volume: 96541,
      Date: new Date("03/19/2021 11:07:00"),
    },
    {
      Open: 651.31,
      High: 651.52,
      Close: 649.26,
      Low: 648.588,
      Volume: 171296,
      Date: new Date("03/19/2021 11:06:00"),
    },
    {
      Open: 652.3425,
      High: 652.88,
      Close: 651.45,
      Low: 651.2,
      Volume: 115588,
      Date: new Date("03/19/2021 11:05:00"),
    },
    {
      Open: 651.6701,
      High: 654.28,
      Close: 652.51,
      Low: 651.6701,
      Volume: 182978,
      Date: new Date("03/19/2021 11:04:00"),
    },
    {
      Open: 649.43,
      High: 652.8473,
      Close: 651.58,
      Low: 649.22,
      Volume: 228059,
      Date: new Date("03/19/2021 11:03:00"),
    },
    {
      Open: 650.0639,
      High: 650.1025,
      Close: 649.31,
      Low: 648.11,
      Volume: 62898,
      Date: new Date("03/19/2021 11:02:00"),
    },
    {
      Open: 650.74,
      High: 650.74,
      Close: 649.87,
      Low: 648.8,
      Volume: 120222,
      Date: new Date("03/19/2021 11:01:00"),
    },
    {
      Open: 648.32,
      High: 650.76,
      Close: 650.76,
      Low: 647.7,
      Volume: 116131,
      Date: new Date("03/19/2021 11:00:00"),
    },
    {
      Open: 647.22,
      High: 648.98,
      Close: 648.27,
      Low: 646.98,
      Volume: 75250,
      Date: new Date("03/19/2021 10:59:00"),
    },
    {
      Open: 646.7799,
      High: 648.4943,
      Close: 647.27,
      Low: 646.7799,
      Volume: 84830,
      Date: new Date("03/19/2021 10:58:00"),
    },
    {
      Open: 647.03,
      High: 648.34,
      Close: 646.6494,
      Low: 646.37,
      Volume: 91544,
      Date: new Date("03/19/2021 10:57:00"),
    },
    {
      Open: 648.778,
      High: 650.11,
      Close: 647.19,
      Low: 646.38,
      Volume: 166552,
      Date: new Date("03/19/2021 10:56:00"),
    },
    {
      Open: 649.13,
      High: 649.78,
      Close: 648.6891,
      Low: 648,
      Volume: 96838,
      Date: new Date("03/19/2021 10:55:00"),
    },
    {
      Open: 650.31,
      High: 650.95,
      Close: 649,
      Low: 648.4942,
      Volume: 181442,
      Date: new Date("03/19/2021 10:54:00"),
    },
    {
      Open: 646.205,
      High: 650.88,
      Close: 650.4097,
      Low: 646.12,
      Volume: 279385,
      Date: new Date("03/19/2021 10:53:00"),
    },
    {
      Open: 642.69,
      High: 646.6199,
      Close: 646.3,
      Low: 642.69,
      Volume: 175907,
      Date: new Date("03/19/2021 10:52:00"),
    },
    {
      Open: 643.3367,
      High: 643.84,
      Close: 642.685,
      Low: 641.78,
      Volume: 134657,
      Date: new Date("03/19/2021 10:51:00"),
    },
    {
      Open: 644.18,
      High: 644.3668,
      Close: 643.2694,
      Low: 642.5,
      Volume: 106562,
      Date: new Date("03/19/2021 10:50:00"),
    },
    {
      Open: 644.765,
      High: 645.27,
      Close: 644.17,
      Low: 643.76,
      Volume: 104413,
      Date: new Date("03/19/2021 10:49:00"),
    },
    {
      Open: 645.1505,
      High: 645.86,
      Close: 644.7,
      Low: 644.35,
      Volume: 127691,
      Date: new Date("03/19/2021 10:48:00"),
    },
    {
      Open: 646.945,
      High: 647.76,
      Close: 645.023,
      Low: 645.023,
      Volume: 220400,
      Date: new Date("03/19/2021 10:47:00"),
    },
    {
      Open: 646.24,
      High: 647.34,
      Close: 646.7301,
      Low: 646,
      Volume: 170415,
      Date: new Date("03/19/2021 10:46:00"),
    },
    {
      Open: 643.27,
      High: 646.99,
      Close: 646.51,
      Low: 642.91,
      Volume: 255142,
      Date: new Date("03/19/2021 10:45:00"),
    },
    {
      Open: 643.0201,
      High: 643.6,
      Close: 643.365,
      Low: 642.3201,
      Volume: 105294,
      Date: new Date("03/19/2021 10:44:00"),
    },
  ];
}
