import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "../appRoutes";

interface AuthModalProps {
  children: ReactNode;
}

export const AuthModal = ({ children }: AuthModalProps) => {
  const navigate = useNavigate();
  return (
    <Dialog open={true} onClose={() => navigate(APPROUTES.APP.ROOT)}>
      <div
        style={{
          padding: "10px",
        }}
      >
        {children}
      </div>
    </Dialog>
  );
};

export const MustLogin = () => {
  const navigate = useNavigate();

  return (
    <Dialog open={true} onClose={() => navigate(APPROUTES.APP.ROOT)}>
      <div
        style={{
          padding: "10px",
        }}
      >
        <DialogContentText>
          You must be logged in to perform that action.
        </DialogContentText>
        <DialogActions>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
          <Button
            variant="outlined"
            onClick={() => navigate(APPROUTES.APP.LOGIN)}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(APPROUTES.APP.REGISTER)}
          >
            Register
          </Button>
          {/* </div> */}
        </DialogActions>
      </div>
    </Dialog>
  );
};
