import { create } from "zustand";

export interface IUseFlagStore {
  FlaggedTickers: string[];
  AddFlag: (symbol: string) => void;
  RemoveFlag: (symbol: string) => void;
  ToggleFlag: (symbol: string) => void;
  IsTickerFlagged: (symbol: string) => boolean;
  ClearAllFlags: () => void;
}

export const useFlagStore = create<IUseFlagStore>((set, get) => ({
  FlaggedTickers: [],
  ClearAllFlags: () => {
    set({ FlaggedTickers: [] });
  },
  ToggleFlag: (symbol: string) => {
    if (get().IsTickerFlagged(symbol)) {
      get().RemoveFlag(symbol);
    } else {
      get().AddFlag(symbol);
    }
  },
  IsTickerFlagged: (symbol: string) => {
    return get().FlaggedTickers.includes(symbol);
  },
  AddFlag: (symbol: string) => {
    if (!get().IsTickerFlagged(symbol)) {
      set((state) => ({ FlaggedTickers: [...state.FlaggedTickers, symbol] }));
    }
  },
  RemoveFlag: (symbol: string) => {
    set((state) => ({
      FlaggedTickers: [...state.FlaggedTickers.filter((f) => f !== symbol)],
    }));
  },
}));
