import { StockData } from "../../../WebChart/src/interfaces";
import { USE_LOCAL } from "../app";
import axios from "./axios-wrapper";

const apiUrl = "/stockdata";

export interface IStockData {
  getDaily(ticker: string): Promise<StockDataReturn | undefined>;
  getIntraday(ticker: string): Promise<StockData[] | undefined>;
  //   createScan(scanItem: ScanItem): Promise<void>;
  //   patchScan(scanItem: ScanItem): Promise<void>;
}

export const GetStockDataApi = (): IStockData => {
  if (USE_LOCAL) {
    throw "not supported";
    // return Dev_ScanApi;
  }

  return StockDataApi;
};

export interface StockDataReturn {
  stockdata: StockData[];
  lastUpdated: Date;
}

export const StockDataApi: IStockData = {
  getIntraday: async (ticker: string): Promise<StockData[] | undefined> => {
    try {
      const response = await axios.get<any[]>(apiUrl + "/intraday", {
        params: {
          ticker: ticker,
        },
      });

      if (response.status === 200) {
        // Set the retrieved data to the state

        var data: StockData[] = response.data.map((f) => ({
          Open: f.o,
          Low: f.l,
          High: f.h,
          Close: f.c,
          Volume: f.v,
          Date: new Date(f.t),
        }));

        return data;
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
  getDaily: async (ticker: string): Promise<StockDataReturn | undefined> => {
    try {
      const response = await axios.get<any>(apiUrl, {
        params: {
          ticker: ticker,
        },
      });

      if (response.status === 200) {
        // Set the retrieved data to the state

        var data: StockData[] = response.data.candles.map((f: any) => ({
          Open: f.o,
          Low: f.l,
          High: f.h,
          Close: f.c,
          Volume: f.v,
          Date: new Date(f.t),
        }));

        return {
          stockdata: data,
          lastUpdated: new Date(response.data.lastUpdate),
        };
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
};
