import { PointTypes } from "../interfaces/PointTypes";
import { Orders } from "../renderer/OrderRenderer";

// export interface IDataManager<T extends PointTypes> {
//   DataExists(): boolean;
//   GetData(): T[] | T[][];
//   GetDateFromIndex(index: number): Date;
//   GetIndexOfTime(time: Date): number;
//   SetData(data: T[] | T[][]): void;
//   GetOrders(): Orders[];
//   SetOrders(orders: Orders[]): void;
// }

// export class DataManager<T extends PointTypes> implements IDataManager<T> {
//   private data: T[] | T[][] = [];
//   private orders: Orders[] = [];

//   DataExists(): boolean {
//     return this.data.length >= 0;
//   }

//   GetData(): T[] | T[][] {
//     return this.data;
//   }

//   GetDateFromIndex(index: number): Date {
//     return this.getForDateIndex()[index].Date;
//   }

//   private getForDateIndex(): T[] {
//     let _d: T[];
//     if (Array.isArray(this.data[0])) {
//       _d = this.data[0] as T[];
//     } else {
//       _d = this.data as T[];
//     }
//     return _d;
//   }

//   GetIndexOfTime(time: Date): number {
//     return this.getForDateIndex().findIndex((f) => f.Date === time);
//   }

//   SetData(data: T[] | T[][]): void {
//     this.data = data;
//   }

//   GetOrders(): Orders[] {
//     return this.orders;
//   }

//   SetOrders(orders: Orders[]): void {
//     this.orders = orders;
//   }
// }

export interface ISingleDataManager<T extends PointTypes> {
  DataExists(): boolean;
  GetData(): T[];
  GetElementAtIndex(data: T[], index: number): T;
  GetDateFromIndex(index: number): Date;
  GetIndexOfTime(time: Date): number;
  SetData(data: T[]): void;
  GetOrders(): Orders[];
  SetOrders(orders: Orders[]): void;
}

export class SingleDataManager<T extends PointTypes>
  implements ISingleDataManager<T>
{
  private data: T[] = [];
  private orders: Orders[] = [];

  DataExists(): boolean {
    return this.data.length >= 0;
  }

  GetData(): T[] {
    return this.data;
  }

  GetElementAtIndex(data: T[], index: number): T {
    return data[index];
  }

  GetDateFromIndex(index: number): Date {
    return this.data[index].Date;
  }

  GetIndexOfTime(time: Date): number {
    return this.data.findIndex((f) => f.Date === time);
  }

  SetData(data: T[]): void {
    this.data = data;
  }

  GetOrders(): Orders[] {
    return this.orders;
  }

  SetOrders(orders: Orders[]): void {
    this.orders = orders;
  }
}
