import { IModule } from "../calculator/Modules";
import { IDataTypeHelper } from "../helpers/DataHelpers";
import { PointTypes } from "../interfaces/PointTypes";
import { ISingleDataManager } from "../managers";
import { XHelpers } from "../XHelpers";
import { YHelpers } from "../YHelpers";

export class BarRenderer implements IModule {
  private xHelper: XHelpers;
  private dataHelper: IDataTypeHelper<PointTypes>;
  private dataMan: ISingleDataManager<PointTypes>;
  private ctx: CanvasRenderingContext2D;
  private yHelper: YHelpers;

  constructor(
    ctx: CanvasRenderingContext2D,
    xHelper: XHelpers,
    yHelper: YHelpers,
    dataMan: ISingleDataManager<PointTypes>,
    dataHelper: IDataTypeHelper<PointTypes>
  ) {
    this.yHelper = yHelper;
    this.ctx = ctx;
    this.xHelper = xHelper;
    this.dataMan = dataMan;
    this.dataHelper = dataHelper;
  }

  calculate = () => {
    return;
  };

  RenderBar = () => {
    const data = this.dataMan.GetData();
    this.RenderData(data);
  };

  RenderData = (data: PointTypes[]) => {
    const ctx = this.ctx;
    ctx.save();

    const pos = this.yHelper.areaProps.topLeft.y + this.yHelper.areaHeight;

    for (
      let index = this.xHelper.maxDataIndex;
      index >= this.xHelper.minDataIndex;
      index--
    ) {
      const element = this.dataMan.GetElementAtIndex(data, index);
      var idxpix = this.xHelper.XIndexToPixel(index);
      var high = this.yHelper.NumYToPixel(element.Volume);

      ctx.fillStyle = this.dataHelper.getFillStyle(element);

      ctx.fillRect(idxpix, high, this.xHelper.elementSize, pos - high);
    }

    ctx.restore();
  };

  render = () => {
    this.RenderBar();
  };
}
