export const IsMarketHours = (): boolean => {
  const now = new Date();
  const dayOfWeek = now.getDay();
  //sun or saturday
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return false;
  }

  const estOffset = now.getTimezoneOffset() + 300; // 300 minutes = 5 hours
  const estNow = new Date(now.getTime() - estOffset * 60 * 1000);
  const est930 = new Date(
    estNow.getFullYear(),
    estNow.getMonth(),
    estNow.getDate(),
    9,
    30,
    0,
    0
  );
  const est1600 = new Date(
    estNow.getFullYear(),
    estNow.getMonth(),
    estNow.getDate(),
    16,
    0,
    0,
    0
  );
  return estNow < est930 || estNow >= est1600;
};
