import React from "react";
import { useFlagStore } from "../store/useFlagStore";
import { useWatchList } from "../store/useWatchListStore";
import { Menu, MenuItem } from "@mui/material";
import { useShallow } from "zustand/react/shallow";

interface SymbolListMenuProps {
  parentId: string;
  ticker: string;
  onClose: () => void;
  onFlagList: () => void;
  onUnFlagList: () => void;
  type: "watchList" | "scan";
}

export const SymbolListMenu: React.FC<SymbolListMenuProps> = ({
  parentId,
  ticker,
  onClose,
  onFlagList,
  onUnFlagList,
  type,
}) => {
  const [ToggleFlag, IsTickerFlagged, ClearAllFlags] = useFlagStore(
    useShallow((s) => [s.ToggleFlag, s.IsTickerFlagged, s.ClearAllFlags])
  );
  const isFlagged = IsTickerFlagged(ticker);
  const [
    watchLists,
    patchWatchListItem,
    deleteWatchListItem,
    clearWatchList,
    deleteWatchList,
  ] = useWatchList(
    useShallow((s) => [
      s.watchLists,
      s.patchWatchListItem,
      s.deleteWatchListItem,
      s.clearWatchList,
      s.deleteWatchList,
    ])
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const watchListOptions = Object.values(watchLists ?? {}).map(
    (item, index) => (
      <MenuItem
        key={item.id && index}
        onClick={() => {
          patchWatchListItem(item.id as string, ticker);
          handleClose();
          onClose();
        }}
      >
        {item.name}
      </MenuItem>
    )
  );

  const handleClearWatchList = async (id: string) => {
    await clearWatchList(id);
  };

  let watchListMenu: React.JSX.Element[] | null = null;

  if (type === "watchList") {
    watchListMenu = [
      <MenuItem
        key="clearwatchlist"
        onClick={() => {
          handleClearWatchList(parentId);
          onClose();
        }}
      >
        Clear Watchlist
      </MenuItem>,
      <MenuItem
        key="deletewatchlist"
        onClick={() => {
          deleteWatchList(parentId);
          onClose();
        }}
      >
        Delete Watchlist
      </MenuItem>,
    ];
  }

  return (
    <>
      <MenuItem
        key="flagorremove"
        onClick={() => {
          ToggleFlag(ticker);
          onClose();
        }}
      >
        {isFlagged ? "Remove Flag" : "Flag Ticker"}
      </MenuItem>
      <MenuItem key="addtolist" onClick={handleClick}>
        Add to watchlist
      </MenuItem>
      {type === "watchList" && (
        <MenuItem
          key="removefromlist"
          onClick={() => {
            deleteWatchListItem(parentId, ticker);
            onClose();
          }}
        >
          Remove from List
        </MenuItem>
      )}
      <MenuItem
        key="flaglist"
        onClick={() => {
          onFlagList();
          onClose();
        }}
      >
        Flag List
      </MenuItem>
      <MenuItem
        key="removeflags"
        onClick={() => {
          onUnFlagList();
          onClose();
        }}
      >
        Unflag List
      </MenuItem>
      <MenuItem
        key="clearallflags"
        onClick={() => {
          ClearAllFlags();
          onClose();
        }}
      >
        Clear All Flags
      </MenuItem>
      <hr />
      {watchListMenu}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {watchListOptions}
      </Menu>
    </>
  );
};
