import { IModule, ModuleProps } from "../calculator/Modules";
import { PointData } from "../interfaces/PointTypes";

export type ChartConfigTypes = LineConfig | LineConfig[];

export interface LineConfig {
  color: string;
}

export class LineRenderer implements IModule {
  constructor(props: ModuleProps, config: LineConfig) {
    this.props = props;
    this.config = config;
  }
  private props: ModuleProps;
  private config: LineConfig;

  calculate = () => {
    return;
  };

  render = () => {
    const data = this.props.dataMan.GetData();
    this.RenderData(data as PointData[], this.config as LineConfig);
  };

  RenderData = (data: PointData[], config: LineConfig) => {
    //todo use this instead and change loop?
    // const data = this.chartMan.chartAspect.visibleData;
    const path = new Path2D();
    this.props.ctx.save();
    this.props.ctx.strokeStyle = config ? config.color : "black";
    for (
      let index = this.props.xHelper.maxDataIndex;
      index >= this.props.xHelper.minDataIndex;
      index--
    ) {
      const element = this.props.dataMan.GetElementAtIndex(
        data,
        index
      ) as PointData;
      var va = this.props.yHelper.NumYToPixel(element.Val);

      const x = this.props.xHelper.XIndexToPixel(index);
      path.lineTo(x, va);
    }
    this.props.ctx.stroke(path);
    this.props.ctx.restore();
  };
}
