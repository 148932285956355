import { RoundToDecimalPlaces } from "./helpers";
import { Point } from "./interfaces";
import { XHelpers } from "./XHelpers";

export interface AreaProps {
  heightPercent: number;
  topLeft: Point;
  heightScale: number;
  maxHigh: number;
  minLow: number;
  offsetY: number;
}

export class YHelpers {
  constructor(
    canvas: HTMLCanvasElement,
    xHelper: XHelpers,
    heightPercent: number,
    UpdateYAspects: () => void
  ) {
    this.canvas = canvas;
    this.xHelper = xHelper;
    this.areaProps = {
      heightPercent: heightPercent,
      topLeft: { x: 0, y: 0 },
      heightScale: 0,
      maxHigh: 0,
      minLow: 0,
      offsetY: 0,
    };
    this.UpdateYAspectsFunc = UpdateYAspects;
  }

  areaProps: AreaProps;
  private xHelper: XHelpers;
  private UpdateYAspectsFunc: () => void;
  private canvas: HTMLCanvasElement;
  private readonly scaleConfig = {
    minYScale: 0,
    maxYScale: 20,
  };

  IsPointInChartArea = (point: Point): boolean => {
    return (
      point && this.IsYInArea(point.y) && this.xHelper.IsXOnGraphArea(point.x)
    );
  };

  IsYInArea = (y: number): boolean => {
    return (
      y > this.areaProps.topLeft.y &&
      y < this.areaProps.topLeft.y + this.areaHeight
    );
  };

  get areaHeight(): number {
    const totalHeight =
      this.canvas.height -
      this.xHelper.padding.top -
      this.xHelper.padding.bottom;

    return totalHeight * this.areaProps.heightPercent;
  }

  NumYToPixel = (price: number): number => {
    var prc = this.areaProps.maxHigh - price;
    var point = RoundToDecimalPlaces(prc * this.areaProps.heightScale, 5);
    return this.areaProps.topLeft.y + point;
  };

  PixelYToNum = (position: number): number => {
    var x =
      this.areaProps.maxHigh -
      (position - this.areaProps.topLeft.y) / this.areaProps.heightScale;
    x = RoundToDecimalPlaces(x, 4);
    return x;
  };

  SetOffsetY = (offsetY: number) => {
    if (
      offsetY < this.scaleConfig.minYScale ||
      offsetY > this.scaleConfig.maxYScale
    ) {
      return;
    }
    this.areaProps.offsetY = offsetY;
    this.UpdateYAspectsFunc();
  };

  UpdateYAspects = (max: number, min: number) => {
    const range = max - min;
    const percent = this.areaProps.offsetY / this.scaleConfig.maxYScale;
    const yChange = range * percent;

    const maxHigh = max + yChange;
    const minLow = min - yChange;

    // if (this.config.chartType === "CandleStick" && minLow < 0) {
    //   minLow = 0;
    // }

    this.areaProps.heightScale = RoundToDecimalPlaces(
      this.areaHeight / (maxHigh - minLow),
      15
    );

    this.areaProps = {
      ...this.areaProps,
      maxHigh: maxHigh > minLow ? maxHigh : minLow,
      minLow: minLow < maxHigh ? minLow : maxHigh,
    };
  };
}
