import React, { useState, useContext, FormEvent } from "react";
import { AuthContext } from "./authContext";
import { Button, TextField, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { AuthModal } from "./authModal";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "../appRoutes";
import { AuthResult } from "./authResult";

export const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string[] | null>(null);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  if (!authContext) {
    throw new Error(
      "useContext(AuthContext) must be used within an AuthProvider"
    );
  }

  const { login } = authContext;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      setError(null); // Clear any previous errors on successful login
      navigate(APPROUTES.APP.ROOT);
    } catch (err: any) {
      setError([err.message]);
    }
  };

  return (
    <AuthModal>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography variant="h5">Login</Typography>
          <TextField
            size="small"
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            size="small"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            startIcon={<LoginIcon />}
          >
            Login
          </Button>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(APPROUTES.APP.REGISTER)}
            >
              Register
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(APPROUTES.APP.FORGOT_PASSWORD)}
            >
              Forgot Password
            </span>
          </div>
          <AuthResult error={error} success="" />
        </div>
      </form>
    </AuthModal>
  );
};
