import React, { useState, FormEvent } from "react";
import { Button, TextField, Typography } from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { AuthModal } from "./authModal";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { axios as baseAxios } from "../api/axios-wrapper";
import { AuthResult } from "./authResult";
import { APPROUTES } from "../appRoutes";

type ResetPasswordParams = {
  token: string;
  email: string;
};

interface ResetPasswordRequest {
  token: string;
  email: string;
  newPassword: string;
}

const apiUrl = "auth/reset";

export const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string[] | null>(null);

  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const token = query.get("token") || ""; // Get the raw token value
  const email = query.get("email") || ""; // Get the raw email value

  const loginClick = () => {
    navigate(APPROUTES.APP.LOGIN);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(apiUrl, {
        token: encodeURIComponent(token),
        email,
        newPassword,
      } as ResetPasswordRequest);
      setMessage("Password has been reset.");
      setError([]);
    } catch (err) {
      setMessage("");
      if (baseAxios.isAxiosError(err) && err.response?.status === 400) {
        // Map the error response to extract error descriptions
        const errorMessages = err.response.data.map(
          (error: { description: string }) => error.description
        );

        // Set the error state to display the error messages
        setError(errorMessages);
      } else {
        setError(["An error occurred. Please try again later."]);
      }
    }
  };

  return (
    <AuthModal>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography variant="h5">Reset Password</Typography>
          <TextField
            label="New Password"
            size="small"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Button
            disabled={message.length > 0}
            type="submit"
            size="small"
            variant="contained"
            startIcon={<SettingsBackupRestoreIcon />}
          >
            Reset Password
          </Button>
        </div>
        <AuthResult error={error} success={message} />
        {message.length > 0 && <Button onClick={loginClick}>Login</Button>}
      </form>
    </AuthModal>
  );
};
