import axios from "./axios-wrapper";
import { Drawing, DrawingResponse } from "../models/drawingModel";

const apiUrl = "/drawing";

function convertDrawingResponse(response: DrawingResponse): Drawing {
  return {
    id: response.id,
    ticker: response.ticker,
    drawings: {
      lines: response.drawings?.lines?.map((line) => ({
        ...line,
        startTime: new Date(line.startTime),
        endTime: new Date(line.endTime),
      })),
    },
  };
}

export const DrawingApi = {
  getDrawing: async (ticker: string): Promise<Drawing | undefined> => {
    const response = await axios.get<DrawingResponse>(
      apiUrl + "?ticker=" + ticker
    );

    return convertDrawingResponse(response.data);
  },
  createDrawing: async (drawing: Drawing): Promise<string | undefined> => {
    const response = await axios.post<string>(apiUrl, drawing);
    return response.data;
  },
  updateDrawing: async (drawing: Drawing): Promise<void> => {
    const response = await axios.patch(apiUrl, drawing);
  },
};
