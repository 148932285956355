import { createCanvas } from "./ChartHelper";
import { Plot, PlotConfig } from "./Plot";
import { XHelpers } from "./XHelpers";
import { YHelpers } from "./YHelpers";
import { IModule } from "./calculator/Modules";
import { HighLow } from "./helpers/DataHelpers";
import { Point } from "./interfaces/Point";
import { PriceScaleRenderer } from "./renderer/PriceScaleRenderer";

export class Area {
  constructor(
    heightPercent: number,
    parentDiv: HTMLDivElement,
    xHelper: XHelpers
  ) {
    this.xHelper = xHelper;

    //canvas elements
    this.canvas = createCanvas(parentDiv);
    this.ctx = <CanvasRenderingContext2D>this.canvas.getContext("2d");

    //default colors
    this.ctx.strokeStyle = "black";

    this.heightPercent = heightPercent;
    this.yHelper = new YHelpers(
      this.canvas,
      xHelper,
      heightPercent,
      this.UpdateYAspects
    );
    this.modules.push(new PriceScaleRenderer(this.ctx, this.yHelper, xHelper));
  }
  private xHelper: XHelpers;
  private ctx: CanvasRenderingContext2D;
  private heightPercent = 0;

  yHelper: YHelpers;
  yHelperLeft!: YHelpers;
  modules: IModule[] = [];
  canvas: HTMLCanvasElement;
  plots: Plot[] = [];

  GetLegend = () => {};

  RenderPlotModules = () => {
    this.ClearCanvas();
    this.RenderModules();
    this.plots.forEach((plot) => {
      plot.renderModules.forEach((module) => {
        module.render();
      });
    });
  };

  AddPlot = (config: PlotConfig) => {
    if (config.scalePosition === "Left") {
      if (!this.yHelperLeft) {
        this.xHelper.AddLeftScale();
        this.yHelper.areaProps.topLeft.x = 45;
        this.yHelperLeft = new YHelpers(
          this.canvas,
          this.xHelper,
          this.heightPercent,
          this.UpdateYAspects
        );
        this.modules.push(
          new PriceScaleRenderer(
            this.ctx,
            this.yHelperLeft,
            this.xHelper,
            config.scalePosition
          )
        );
      }

      var plot = new Plot(config, this.ctx, this.yHelperLeft, this.xHelper);
      this.plots.push(plot);
    } else {
      var plot = new Plot(config, this.ctx, this.yHelper, this.xHelper);
      this.plots.push(plot);
    }
  };

  RenderModules = () => {
    this.modules.forEach((module) => {
      module.render();
    });
  };

  GetHighLow = (pos: "Left" | "Right"): HighLow => {
    var min = Number.MAX_SAFE_INTEGER;
    var max = Number.MIN_SAFE_INTEGER;
    this.plots.forEach((plot) => {
      if (plot.config.scalePosition !== pos) {
        return;
      }
      const elements = plot.data.GetData();
      var hiLo = plot.dataHelper.getHighLow(
        elements,
        this.xHelper.minDataIndex,
        this.xHelper.maxDataIndex
      );
      if (hiLo.high > max) {
        max = hiLo.high;
      }
      if (hiLo.low < min) {
        min = hiLo.low;
      }
    });

    return { high: max, low: min };
  };

  UpdateYAspects = () => {
    var hiLo = this.GetHighLow("Right");
    this.yHelper.UpdateYAspects(hiLo.high, hiLo.low);
    if (this.yHelperLeft) {
      hiLo = this.GetHighLow("Left");
      this.yHelperLeft.UpdateYAspects(hiLo.high, hiLo.low);
    }
  };

  //TODO
  isMouseOnCanvasPriceScale = (mouse: Point): boolean => {
    const ca = this.xHelper;
    if (
      mouse.x > ca.chartWidth + ca.padding.left &&
      mouse.x < ca.chartWidth + ca.padding.left + ca.padding.right
    ) {
      if (this.yHelper.IsYInArea(mouse.y)) {
        document.body.style.cursor = "ns-resize";
        return true;
      }
    }
    document.body.style.cursor = "default";
    return false;
  };

  ClearCanvas = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };
}
