export function ResetCanvas(
  ctx: CanvasRenderingContext2D
  // canvasMan: CanvasManager,
  // chartMan: CanvasAreaManager
) {
  if (!ctx.canvas) return;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  ctx.save();
  //   ctx.fillStyle = "lightblue";
  //   ctx.fillRect(
  //     canvasMan.padding.left,
  //     canvasMan.padding.top,
  //     canvasMan.chartXAspect.chartWidth,
  //     chartMan.chartYAspect.areaHeight
  //   );
  ctx.restore();
}
