import { FlagOutlined } from "@mui/icons-material";
import FlagIcon from "@mui/icons-material/Flag";
import React from "react";

export interface SvgFlagProps {
  isFlagged: boolean;
}

const styles = {
  fontSize: "1rem",
};

export const SvgFlag = ({ isFlagged }: SvgFlagProps) => {
  if (isFlagged) {
    return <FlagIcon sx={styles} />;
  } else {
    return <FlagOutlined sx={styles} />;
  }
};
