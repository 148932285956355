import { create } from "zustand";

interface EventBusStore {
  listeners: ((event: string, data: any) => void)[];
  addListener: (listener: (event: string, data: any) => void) => void;
  removeListener: (listener: (event: string, data: any) => void) => void;
  emitEvent: (event: string, data: any) => void;
}

export const useEventBusStore = create<EventBusStore>((set, get) => ({
  listeners: [],

  addListener: (listener) =>
    set((state) => ({ listeners: [...state.listeners, listener] })),

  removeListener: (listener) =>
    set((state) => ({
      listeners: state.listeners.filter((l) => l !== listener),
    })),

  emitEvent: (event, data) => {
    for (const listener of get().listeners) {
      listener(event, data);
    }
  },
}));
