import { create } from "zustand";
import { WatchList, WatchLists } from "../Lists/symbol-list-interface";
import { GetWatchListApi, WatchListApi } from "../api/index";

type WatchListState = {
  watchListId: string;
  watchLists: WatchLists | null;
  SetWatchLists: (watchLists: WatchLists) => void;
  patchWatchListItem: (id: string, ticker: string) => Promise<void>;
  getWatchListItem: (watchId: string) => Promise<WatchList>;
  createWatchList: (watchList: WatchList) => Promise<string>;
  deleteWatchListItem: (id: string, ticker: string) => Promise<void>;
  clearWatchList: (id: string) => Promise<void>;
  deleteWatchList: (id: string) => Promise<void>;
};

export const useWatchList = create<WatchListState>((set, get) => ({
  deleteWatchList: async (id: string) => {
    await WatchListApi.deleteWatchList(id);
    set((state) => {
      const updatedWatchLists = { ...state.watchLists }; // Create a shallow copy of watchLists
      delete updatedWatchLists[id]; // Remove the watchlist by id
      return {
        watchLists: updatedWatchLists, // Return the updated watchLists
      };
    });
  },
  clearWatchList: async (id: string) => {
    await WatchListApi.clearWatchList(id);
    set((state) => ({
      watchLists: {
        ...state.watchLists,
        [id]: {
          //todo better way than ! - ?
          ...state.watchLists![id],
          symbols: [],
        },
      },
    }));
  },
  deleteWatchListItem: async (id: string, ticker: string) => {
    // Await the API call to ensure it completes before proceeding
    await WatchListApi.deleteWatchListItem(id, ticker);

    // Get the current watch lists
    const wl = get().watchLists;
    if (!wl) {
      return; // If watchLists is null or undefined, exit the function
    } else {
      // Check if the specified id exists in watchLists
      if (!wl[id]) {
        return; // Exit if the watchlist for the given id doesn't exist
      }

      // Filter out the symbol with the specified ticker
      const refreshed = wl[id].symbols.filter((s) => s.ticker !== ticker);

      // Update the state with the new watch list
      set((state) => ({
        watchLists: {
          ...state.watchLists,
          [id]: {
            ...wl[id], // Use wl[id] to retain the existing properties
            symbols: refreshed,
          },
        },
      }));
    }
  },

  createWatchList: async (watchList: WatchList) => {
    const newId = await WatchListApi.createWatchList(watchList);
    watchList.id = newId;

    set((state) => ({
      watchLists: { ...state.watchLists, [watchList.id as string]: watchList },
    }));

    return Promise.resolve(newId as string);
  },
  getWatchListItem: async (watchId: string): Promise<WatchList> => {
    try {
      const watchList = (await GetWatchListApi().getWatchList(
        watchId
      )) as WatchList;
      set((state) => ({
        watchLists: {
          ...state.watchLists,
          [watchList.id as string]: watchList,
        },
      }));

      return watchList;
    } catch (error) {
      // Handle error, you can also throw it so it can be caught by the caller
      console.error("Fetch failed", error);
      throw error;
    }
  },
  patchWatchListItem: async (id: string, ticker: string) => {
    const watchLists = get().watchLists;
    if (watchLists == null) {
      return;
    }
    const watchList = watchLists[id];

    if (
      watchList &&
      watchList.symbols.findIndex((s) => s.ticker === ticker) > -1
    ) {
      console.log("Ticker already exists in the watchlist.");
      return;
    }

    var t = watchList.symbols.concat({ ticker: ticker, price: 0, change: 0 });
    watchList.symbols = t;

    await GetWatchListApi().patchWatchList(id, ticker);
    set((state) => ({
      watchLists: {
        ...state.watchLists,
        [id]: watchList,
      },
    }));
  },
  SetWatchLists: (watchLists: WatchLists) => {
    set(() => ({
      watchLists: watchLists,
    }));
  },
  watchLists: null,
  watchListId: "",
}));
