import { AlertColor } from "@mui/material";
import { create } from "zustand";

interface useSnackStore {
  readonly isSnackOpen: boolean;
  Close: () => void;
  readonly SnackMessage: string;
  SetSnackMessage: (message: string, severity?: AlertColor) => void;
  readonly severity: AlertColor;
}

export const useSnackStore = create<useSnackStore>((set, get) => ({
  severity: "success",
  isSnackOpen: false,
  Close: () =>
    set(() => ({
      isSnackOpen: false,
      SnackMessage: "",
      severity: "success",
    })),
  SnackMessage: "",
  SetSnackMessage: (message: string, severity: AlertColor = "success") =>
    set(() => ({
      SnackMessage: message,
      isSnackOpen: true,
      severity: severity,
    })),
}));
