import { WatchList, WatchLists } from "../Lists/symbol-list-interface";
import { IWatchListapi } from "../api/watchlist-api";

class Dev_WatchListApiData {
  static wl: WatchLists = {
    "7f5acf16-6c45-466d-992a-9e976f7f0c92": {
      symbols: [
        { ticker: "TSLA", price: 100.0, change: 1 },
        { ticker: "AMZN", price: 200, change: 1.5 },
        { ticker: "MSFT", price: 150, change: 1.2 },
        { ticker: "AAPL", price: 175, change: 10 },
      ],
      id: "7f5acf16-6c45-466d-992a-9e976f7f0c92",
      name: "test watchlist",
    },
  };
}

export const Dev_WatchListApi: IWatchListapi = {
  getWatchList: function (watchId: string): Promise<WatchList | undefined> {
    throw new Error("Function not implemented.");
  },
  getWatchLists: function (): Promise<WatchLists | undefined> {
    return Promise.resolve(Dev_WatchListApiData.wl);
  },
  createWatchList: function (
    watchItem: WatchList
  ): Promise<string | undefined> {
    throw new Error("Function not implemented.");
    // Implement createWatchList logic here
  },
  patchWatchList: function (id: string, ticker: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
  deleteWatchListItem: function (id: string, ticker: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
  clearWatchList: function (id: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
  deleteWatchList: function (id: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
};
