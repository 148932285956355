import { IModule, ModuleProps } from "./calculator/Modules";
import {
  BarDataHelper,
  CandleDataHelper,
  IDataTypeHelper,
  PointDataHelper,
} from "./helpers";
import { PointTypes } from "./interfaces";
import { ISingleDataManager, SingleDataManager } from "./managers";
import {
  LineConfig,
  LineRenderer,
  BollingerRenderer,
  BarRenderer,
  CandleStick,
  SimpleMovingAverage,
} from "./renderer";
import { XHelpers } from "./XHelpers";
import { YHelpers } from "./YHelpers";

export interface PlotConfig {
  type: "Candle" | "Line" | "Bar";
  color?: string;
  scalePosition?: "Left" | "Right";
}

export class Plot {
  config: PlotConfig;
  dataHelper: IDataTypeHelper<PointTypes>;
  data: ISingleDataManager<PointTypes>;
  renderModules: IModule[] = [];
  constructor(
    config: PlotConfig,
    ctx: CanvasRenderingContext2D,
    yHelper: YHelpers,
    xHelper: XHelpers
  ) {
    this.config = {
      ...config,
      scalePosition: config.scalePosition ?? "Right",
      color: config.color ?? "black",
    };
    this.data = new SingleDataManager();

    var modProps: ModuleProps = {
      ctx,
      yHelper,
      dataMan: this.data,
      xHelper,
    };

    if (config.type === "Candle") {
      this.dataHelper = new CandleDataHelper();
      var rend = new CandleStick(modProps);
      this.AddModule(rend);

      var boll = new BollingerRenderer(modProps, this.dataHelper);
      this.AddModule(boll);

      var sma = new SimpleMovingAverage(
        modProps,
        10,
        "orange",
        this.dataHelper
      );
      this.AddModule(sma);

      var sma = new SimpleMovingAverage(
        modProps,
        20,
        "purple",
        this.dataHelper
      );
      this.AddModule(sma);
    } else if (config.type === "Line") {
      this.dataHelper = new PointDataHelper();
      const lnConfig: LineConfig = {
        color: config.color ?? "black",
      };
      var line = new LineRenderer(modProps, lnConfig);
      this.AddModule(line);
    } else if (config.type === "Bar") {
      this.dataHelper = new BarDataHelper();
      const bar = new BarRenderer(
        ctx,
        xHelper,
        yHelper,
        this.data,
        this.dataHelper
      );
      this.AddModule(bar);
    } else {
      throw new Error("Invalid Plot Type");
    }
  }

  SetData = (data: PointTypes[]) => {
    this.data.SetData(data);
  };

  AddModule = (module: IModule) => {
    this.renderModules.push(module);
  };
}
