import React, { useContext } from "react";
import { AuthModal } from "./authModal";
import { Button } from "@mui/material";
import { AuthContext } from "./authContext";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "../appRoutes";

export const LogOut = () => {
  const logout = useContext(AuthContext)?.logout;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout?.();
    navigate(APPROUTES.APP.ROOT);
  };
  return (
    <AuthModal>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>Are you sure you want to Logout?</div>
        <Button variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </AuthModal>
  );
};
