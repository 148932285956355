import {
  AppBar,
  Box,
  Button,
  DialogActions,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { FormEvent, useRef } from "react";
import { useAppStore } from "../store";
import { useWatchList } from "../store/useWatchListStore";
import { useNavigate, useParams } from "react-router-dom";
import { APPROUTES } from "../appRoutes";
import { AuthModal } from "../auth/authModal";
import { useShallow } from "zustand/react/shallow";

type Params = {
  id: string;
};

export const AddToWatchListModal = () => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { id } = useParams<Params>();

  const navigate = useNavigate();

  const [watchListId, watchLists, patchWatchListItem] = useWatchList(
    useShallow((s) => [s.watchListId, s.watchLists, s.patchWatchListItem])
  );

  const ActiveLayout = useAppStore((s) => s.ActiveLayout);

  if (watchLists === null) {
    return;
  }

  const handleAdd = async (): Promise<void> => {
    if (textFieldRef.current !== null) {
      const newTicker = textFieldRef.current.value.toUpperCase();
      // const update = res.symbols.concat({ ticker: newTicker });
      // res.symbols = update;
      await patchWatchListItem(id as string, newTicker);
      clearAndClose();
    }
  };

  const clearAndClose = (): void => {
    if (textFieldRef.current !== null) {
      textFieldRef.current.value = "";
    }

    navigate(APPROUTES.APP.ROOT);
  };

  const handleCancel = (): void => {
    clearAndClose();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault(); // Prevent the default form submit action (page reload)
    handleAdd(); // Call the handleAdd function
  };

  return (
    <AuthModal>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" gutterBottom>
              Add Ticker to List
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        m={2}
        sx={{
          "& .MuiTextField-root": { m: 1 },
          flexGrow: 1,
        }}
      >
        <TextField
          focused={true}
          autoFocus={true}
          inputRef={textFieldRef}
          id="outlined-basic"
          label="Ticker"
          variant="outlined"
          size="small"
          required
          inputProps={{
            autoComplete: "off",
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={handleAdd}>Add</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </AuthModal>
  );
};
