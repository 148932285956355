import { RoundToDecimalPlaces, RoundTwo } from "./helpers";
import { LineData, Point } from "./interfaces";

export class XHelpers {
  constructor(
    elementSpacing: number,
    elementSize: number,
    canvasWidth: number
  ) {
    this.offsetX = 0;
    this.keys = [];
    this.spaceBetweenElements = elementSpacing;
    this.elementSize = elementSize;
    this.canvasWidth = canvasWidth;
    this.minDataIndex = 0;
    this.maxDataIndex = 0;
    this.maxElementsToShow = 0;
    this.zoomFactorX = 1;
    this.padding = { top: 20, right: 45, bottom: 0, left: 0 };

    this.origSize = elementSize;
    this.origSpacing = elementSpacing;
    this.isShowingLatest = true;
  }

  isShowingLatest: boolean;
  origSpacing: number;
  origSize: number;
  canvasWidth: number;
  minDataIndex: number;
  maxDataIndex: number;
  maxElementsToShow: number;
  offsetX: number;
  keys: Date[];
  spaceBetweenElements: number;
  elementSize: number;
  zoomFactorX: number;
  padding: { top: number; right: number; bottom: number; left: number };
  private readonly scaleConfig = {
    minZoom: 0.15,
    maxZoom: 2.5,
  };
  hasLeftscale = false;

  AddLeftScale = () => {
    this.padding.left = 45;
    this.hasLeftscale = true;
  };

  SetOffsetX = (offSetX: number) => {
    if (offSetX < 0) {
      return;
    }

    this.offsetX = offSetX;
    this.UpdateXAspects();
  };

  TimeToPosition = (time: Date): number => {
    var itemIdx = this.keys.findIndex(
      (key) => key.getTime() === time.getTime()
    );
    return this.XIndexToPixel(itemIdx);
  };

  SetZoom = (zoomFactorX: number) => {
    zoomFactorX = RoundToDecimalPlaces(zoomFactorX, 4);
    if (
      zoomFactorX > this.scaleConfig.minZoom &&
      zoomFactorX < this.scaleConfig.maxZoom
    ) {
      this.zoomFactorX = zoomFactorX;

      this.spaceBetweenElements = RoundTwo(this.origSpacing * this.zoomFactorX);
      this.elementSize = RoundTwo(this.origSize * this.zoomFactorX);
      this.UpdateXAspects();
    }
  };

  get chartWidth(): number {
    return this.canvasWidth - this.padding.left - this.padding.right;
  }

  UpdateCanvasWidth = (canvasWidth: number) => {
    this.canvasWidth = canvasWidth;
  };

  UpdateXAspects = (): void => {
    const eleTotalWidth = this.ElementAndSpaceWidth();
    const numElementsToRender = Math.floor(this.chartWidth / eleTotalWidth);

    //our first element based on offset
    const firstElementOffset = Math.ceil(this.offsetX / eleTotalWidth);

    const data = this.keys;
    let totalData = data.length - 1;
    let maxIndex = totalData - firstElementOffset;
    //80-15 = 65 is our max item

    var minIndex = maxIndex - numElementsToRender;
    if (minIndex <= 0) {
      minIndex = 0;
    }

    this.isShowingLatest = maxIndex === totalData;
    this.minDataIndex = minIndex;
    this.maxDataIndex = maxIndex;
    this.maxElementsToShow = numElementsToRender;
  };

  IsXOnGraphArea = (x: number): boolean => {
    return x > this.padding.left && x < this.chartWidth + this.padding.left;
  };

  IsOnRightScale = (x: number): boolean => {
    return (
      x > this.chartWidth + this.padding.left &&
      x < this.chartWidth + this.padding.right + this.padding.left
    );
  };

  IsOnLeftScale = (x: number): boolean => {
    return this.hasLeftscale && x < this.padding.left && x > 0;
  };

  IsIndexOngraph = (index: number): boolean => {
    return index <= this.maxDataIndex && index >= this.minDataIndex;
  };

  PixelToIndex = (mousePosX: number): number => {
    const fromEdge = this.chartWidth + this.padding.left - mousePosX;
    const space = Math.floor(fromEdge / this.ElementAndSpaceWidth());
    const num = this.maxDataIndex - this.minDataIndex - space;
    return num;
  };

  XIndexToPixel = (idx: number): number => {
    var pos = (this.maxDataIndex - idx + 1) * this.ElementAndSpaceWidth();
    return this.chartWidth + this.padding.left - pos;
  };

  ElementAndSpaceWidth = (): number => {
    return this.spaceBetweenElements + this.elementSize;
  };
}
