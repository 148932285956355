import { MenuActions } from "../interfaces/MenuActions";

export type MenuType = "main" | "drawing";

export interface ContextMenu {
  action: string;
  shortCut: string;
  event: () => void;
}

export class MenuRenderer {
  contextMenu: HTMLUListElement | null = null;
  selectedMenuAction = MenuActions.PanAndZoom;
  //private htmlElements: HTMLElement[] = [];

  private menuType: MenuType = "main";

  constructor() {
    // Register event handlers
    document.addEventListener("click", (e) => this.hideOnOutClick(e));
    // Add the context menu to the body
    // this.htmlElements.push(this.contextMenu);
  }

  SetMenu(menuType: MenuType, menu: ContextMenu[] = this.mainMenuItems) {
    this.menuType = menuType;
    this.theMenu = menu;
  }

  private theMenu: ContextMenu[] | null = null;

  destroy() {
    // this.htmlElements.forEach((el) => el.remove());
    // document.removeEventListener("click", (e) => this.hideMenu(e));
  }

  changeSelection(selection: number) {
    this.selectedMenuAction = selection;
  }

  public readonly mainMenuItems: ContextMenu[] = [
    {
      action: "zoom/pan (p)",
      shortCut: "p",
      event: () => {
        this.selectedMenuAction = 0;
      },
    },
    {
      action: "draw line (l)",
      shortCut: "l",
      event: () => {
        this.selectedMenuAction = 1;
      },
    },
  ];

  private createMenuRoot(): HTMLUListElement {
    let ul = document.createElement("ul");
    ul.id = "context-menu";
    ul.classList.add("context-menu");

    ul.onmouseleave = () => {
      this.hideMenu();
    };

    const li = document.createElement("li");
    li.textContent = "Menu shortcut: Alt + (letter)";

    ul.appendChild(li);

    return ul;
  }

  private createMainContextMenu(): HTMLUListElement {
    let ul = this.createMenuRoot();

    var menu: ContextMenu[] = this.mainMenuItems;
    if (this.menuType !== "main" && this.theMenu) {
      menu = this.theMenu;
    }

    // Add menu items
    menu.forEach((item) => {
      let li = document.createElement("li");
      li.textContent = item.action;
      li.addEventListener("click", (e) => {
        e.stopPropagation();
        item.event();
        this.hideMenu();
      });
      ul.appendChild(li);
    });

    return ul;
  }

  showElementAtMousePosition = (
    element: HTMLElement,
    mouseX: number,
    mouseY: number
  ) => {
    // Get the element's dimensions
    const elementRect = element.getBoundingClientRect();

    // Calculate initial position based on mouse position
    let leftPosition = mouseX;
    let topPosition = mouseY;

    // Check if the element overflows the right edge of the viewport
    if (mouseX + elementRect.width > window.innerWidth) {
      // Position to the left of the mouse if it would overflow to the right
      leftPosition = mouseX - elementRect.width;
    }

    // Check if the element overflows the bottom edge of the viewport
    if (mouseY + elementRect.height > window.innerHeight) {
      // Position above the mouse if it would overflow to the bottom
      topPosition = mouseY - elementRect.height;
    }

    // Apply the calculated position to the element
    element.style.left = `${leftPosition}px`;
    element.style.top = `${topPosition}px`;

    // Make sure the element is visible
    element.style.visibility = "visible";
  };

  rightClick(e: MouseEvent) {
    e.preventDefault();

    if (this.eventIsMenu(e)) {
      return;
    }

    if (!this.contextMenu) {
      this.contextMenu = this.createMainContextMenu();
      document.body.appendChild(this.contextMenu);
    }

    this.showElementAtMousePosition(this.contextMenu, e.pageX, e.pageY);
  }

  hideOnOutClick(e: MouseEvent) {
    if (this.eventIsMenu(e)) {
      return;
    }

    this.hideMenu();
  }

  eventIsMenu(e: MouseEvent) {
    if (e) {
      let target = e.target as HTMLElement;
      // Don't hide the menu if we're clicking on it
      return target.closest(".context-menu");
    }
  }

  hideMenu() {
    const element = document.getElementById("context-menu");
    if (element) {
      element.remove();
    }

    this.menuType = "main";
    this.contextMenu = null;
    return;
  }
}
