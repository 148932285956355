import { Dev_LayoutApi } from "../api_dev/layout-api";
import { USE_LOCAL } from "../app";
import { LayoutItem, Layouts } from "../models/layoutModels";
import axios from "./axios-wrapper";

const apiUrl = "/layout";

export interface ILayoutApi {
  getLayouts(): Promise<Layouts | undefined>;
  addLayout(layout: LayoutItem): Promise<string | undefined>;
  patchLayout(layout: LayoutItem): Promise<void>;
}

export const GetLayoutApi = (): ILayoutApi => {
  if (USE_LOCAL) {
    return Dev_LayoutApi;
  }

  return LayoutApi;
};

export const LayoutApi: ILayoutApi = {
  getLayouts: async (): Promise<Layouts | undefined> => {
    try {
      const response = await axios.get<Layouts>(apiUrl);
      return response.data;
    } catch (error) {
      return undefined;
    }
  },

  addLayout: async (layout: LayoutItem): Promise<string | undefined> => {
    try {
      var res = await axios.post(apiUrl, layout);
      return res.data;
    } catch (error) {
      console.log("Error:", error);
    }
  },
  patchLayout: async (layout: LayoutItem): Promise<void> => {
    try {
      const resp = await axios.patch(apiUrl, layout);
    } catch (error) {
      console.error("Error:", error);
    }
  },
};
