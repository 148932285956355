import { ILayoutApi } from "../api/layout-api";
import { LayoutItem, Layouts } from "../models/layoutModels";

class LayoutApiData {
  static layout: Layouts = {
    "a4efc371-4aeb-40c5-8d60-b7a25e9e0d74": {
      containers: [
        {
          w: 32,
          h: 8,
          x: 0,
          y: 0,
          i: "left",
          moved: false,
          static: false,
          type: "chart",
          props: { ticker: "TSLA", timeFrame: "1day" },
          selectedId: "abc",
        },
        {
          w: 4,
          h: 3,
          x: 32,
          y: 0,
          i: "7f5acf16-6c45-466d-992a-9e976f7f0c92",
          moved: false,
          static: false,
          type: "watchList",
          props: undefined,
          selectedId: "abc",
        },
        {
          w: 4,
          h: 3,
          x: 32,
          y: 3,
          i: "2953f204-268b-4405-917a-64b2f2ecfac8",
          moved: false,
          static: false,
          type: "scan",
          props: undefined,
          selectedId: "abc",
        },
      ],
      id: "a4efc371-4aeb-40c5-8d60-b7a25e9e0d74",
      name: "",
      description: "",
    },
  };
}

export const Dev_LayoutApi: ILayoutApi = {
  getLayouts: function (): Promise<Layouts | undefined> {
    return Promise.resolve(LayoutApiData.layout);
  },
  addLayout: function (layout: LayoutItem): Promise<string | undefined> {
    throw new Error("Function not implemented.");
  },
  patchLayout: function (layout: LayoutItem): Promise<void> {
    LayoutApiData.layout[layout.id as string] = layout;
    return Promise.resolve();
  },
};
