import { IModule, ModuleProps } from "../../calculator/Modules";
import { IDataTypeHelper } from "../../helpers/DataHelpers";
import { PointTypes } from "../../interfaces/PointTypes";

export class BollingerRenderer implements IModule {
  props: ModuleProps;
  private dataHelper: IDataTypeHelper<PointTypes>;

  constructor(props: ModuleProps, dataHelper: IDataTypeHelper<PointTypes>) {
    this.props = props;
    this.dataHelper = dataHelper;
  }

  private bb_period = 20; // Bollinger Bands period
  private bb_deviations = 2; // Bollinger Bands standard deviations

  private bolls!: any[];

  render = () => {
    this.calculate();
    const path = new Path2D();
    const pathlow = new Path2D();
    this.props.ctx.save();
    this.props.ctx.strokeStyle = "red";

    for (
      let i = this.props.xHelper.minDataIndex;
      i <= this.props.xHelper.maxDataIndex;
      i++
    ) {
      var pos = this.bolls[i];
      if (!pos) {
        continue;
      }
      const x =
        this.props.xHelper.XIndexToPixel(i) +
        this.props.xHelper.ElementAndSpaceWidth() / 2;
      path.lineTo(x, pos.yHigh);
      pathlow.lineTo(x, pos.yLow);
    }

    this.props.ctx.stroke(path);
    this.props.ctx.stroke(pathlow);
    this.props.ctx.restore();
  };

  calculate = () => {
    const data = this.props.dataMan.GetData();
    this.bolls = [data.length];

    if (Array.isArray(data[0])) {
      console.log("TODO calc for two");
      return;
    }

    for (let i = this.bb_period - 1; i <= data.length; i++) {
      var res = this.calculateBollingerBands(data as PointTypes[], i);
      if (res == null) {
        //todo does this get hit?
        console.log("should this be null!??!");
        return;
      }

      const yHigh = this.props.yHelper.NumYToPixel(res.upperBand);
      const yLow = this.props.yHelper.NumYToPixel(res.lowerBand);

      this.bolls[i] = { yHigh, yLow };
    }
  };

  calculateBollingerBands(data: PointTypes[], currentIndex: number) {
    if (currentIndex < this.bb_period - 1) {
      // Not enough data to calculate Bollinger Bands
      return null;
    }

    const subset = data.slice(
      currentIndex - this.bb_period + 1,
      currentIndex + 1
    );
    const prices = subset.map((item: PointTypes) =>
      this.dataHelper.getCalcValue(item)
    );
    const average =
      prices.reduce((sum: number, price: number) => sum + price, 0) /
      this.bb_period;
    const stdDeviation = Math.sqrt(
      prices.reduce(
        (sum: number, price: number) => sum + Math.pow(price - average, 2),
        0
      ) / this.bb_period
    );

    const upperBand = average + this.bb_deviations * stdDeviation;
    const lowerBand = average - this.bb_deviations * stdDeviation;
    return { upperBand, lowerBand };
  }
}
