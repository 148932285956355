import {
  BaseItem,
  WatchList,
  WatchLists,
} from "../Lists/symbol-list-interface";
import { Dev_WatchListApi } from "../api_dev/watchlist-api";
import { USE_LOCAL } from "../app";
import axios from "./axios-wrapper";

const apiUrl = "/watchlist";

export interface IWatchListapi {
  clearWatchList(id: string): Promise<void>;
  getWatchList(watchId: string): Promise<WatchList | undefined>;
  getWatchLists(): Promise<WatchLists | undefined>;
  createWatchList(watchItem: BaseItem): Promise<string | undefined>;
  patchWatchList(id: string, ticker: string): Promise<void>;
  deleteWatchListItem(id: string, ticker: string): Promise<void>;
  deleteWatchList(id: string): Promise<void>;
}

export const GetWatchListApi = (): IWatchListapi => {
  if (USE_LOCAL) {
    return Dev_WatchListApi;
  }

  return WatchListApi;
};

export const WatchListApi: IWatchListapi = {
  deleteWatchList: async (id: string): Promise<void> => {
    await axios.delete(apiUrl + "/" + id);
  },
  clearWatchList: async (id: string): Promise<void> => {
    await axios.delete(apiUrl + "/clear/" + id);
  },
  deleteWatchListItem: async (id: string, ticker: string): Promise<void> => {
    try {
      const response = await axios.delete(apiUrl + "/" + id + "/" + ticker);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  },
  getWatchList: async (watchId: string): Promise<WatchList | undefined> => {
    const response = await axios.get<WatchList>(apiUrl + "/" + watchId);
    return response.data;
  },
  getWatchLists: async (): Promise<WatchLists | undefined> => {
    try {
      const response = await axios.get<WatchLists>(apiUrl);
      return response.data;
    } catch (x) {
      return undefined;
    }
  },
  createWatchList: async (watchItem: BaseItem): Promise<string | undefined> => {
    try {
      const response = await axios.post(apiUrl, watchItem);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
  patchWatchList: async (id: string, ticker: string): Promise<void> => {
    try {
      const resp = await axios.patch(apiUrl, { Id: id, Ticker: ticker });
      return resp.data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
};
