import { WormResult } from "../../../WebChart/src/_forDev/devData";
import { USE_LOCAL } from "../app";
import axios from "./axios-wrapper";

const apiUrl = "/worm";

export interface WormApiProps {
  getWorm(): Promise<WormResult | undefined>;
}

export const WormApi: WormApiProps = {
  getWorm: async (): Promise<WormResult | undefined> => {
    try {
      const response = await axios.get<any>(apiUrl);

      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
};
