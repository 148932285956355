import { FormulaItem, Formulas } from "../formula/formula-interfaces";

export const getFormulaById = (
  formulas: Formulas,
  formulaId: string
): FormulaItem | undefined => {
  const formulaItemsArray = Object.values(formulas);

  // Use Array.find() to find the FormulaItem with the specified Id
  return formulaItemsArray.find((formulaItem) => formulaItem.Id === formulaId);
};

export const getFormulaSearch = (
  formulas: Formulas,
  searchTerm: string
): FormulaItem[] => {
  const formulaItemsArray = Object.values(formulas);

  // Use Array.find() to find the FormulaItem with the specified Id
  return formulaItemsArray.filter(
    (formulaItem) => formulaItem.Name.toLowerCase().indexOf(searchTerm) >= 0
  );
};
