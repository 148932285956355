import _ from "lodash";
import React, { ChangeEvent, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CheckboxesTags from "../Lists/autocomplete-checkbox";
import { Formulas } from "../formula/formula-interfaces";
import ConfirmationDialog from "../shared/confirmation";
import { getFormulaById } from "../shared/formula-helper";
import { AppModalProps } from "../models/modalprops";
import { isEqual } from "../shared/object-comparison";
import { useFormulaStore } from "../store/useFormulaStore";
import { useScanStore } from "../store/useScanStore";
import { useSnackStore } from "../store/useSnackStore";
import { ScanCriteria, ScanItem } from "./scan-interfaces";
import { useShallow } from "zustand/react/shallow";

interface ScannerModalProps extends AppModalProps {
  Scan: ScanItem;
  Formulas: Formulas;
}

export const ScannerConfigModal = ({
  open,
  onClose,
  Scan,
  Formulas,
}: ScannerModalProps) => {
  const [getSelectedFormulaId, setSelectedFormulaId] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [getScan, setScan] = useState(_.cloneDeep(Scan));
  const [getInitScan, setInitScan] = useState(Scan);

  const UpdateScan = useScanStore((s) => s.UpdateScan);
  const SetFormulaModalOpen = useFormulaStore((s) => s.SetFormulaModalOpen);
  const [SetSnackMessage, Close] = useSnackStore(
    useShallow((s) => [s.SetSnackMessage, s.Close])
  );

  const selectedFormula = Formulas[getSelectedFormulaId];
  const selectedScanFormula = getScan.Formulas.find(
    (f) => f.FormulaId == getSelectedFormulaId
  );

  const textFieldRef = useRef<HTMLInputElement>(null);

  const isFormDirty = () => {
    return !isEqual(getInitScan, getScan);
  };

  const handleClose = () => {
    if (isFormDirty()) {
      setOpenConfirm(true); // Show the confirmation dialog
    } else {
      onClose(); // No unsaved changes, close the dialog
    }
  };

  const setNewUniverse = (values: number[]) => {
    const _scan = getScan;
    _scan.Universe = values;
    setScan(_scan);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _scan = getScan;
    _scan.Name = event.target.value;
    setScan(_scan);
  };

  const handleSave = () => {
    const _scan = getScan;
    if (textFieldRef.current !== null) {
      _scan.Name = textFieldRef.current.value;
    }
    UpdateScan(_scan).then(() => {
      SetSnackMessage("Saved Scan");
      setInitScan(_.cloneDeep(_scan));
    });
  };

  const AddFormulaClick = () => {
    SetFormulaModalOpen(true);
  };

  const onRemoveItem = (formulaId: string) => {
    var updated = getScan.Formulas.filter(
      (criteria) => criteria.FormulaId !== formulaId
    );
    const updatedScanItem: ScanItem = {
      ...getScan,
      Formulas: updated,
    };

    setScan(updatedScanItem);
  };

  const handleFormulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const scan = getScan;
    const curr = getScan.Formulas.find(
      (f) => f.FormulaId === getSelectedFormulaId
    ) as ScanCriteria;
    curr.Evaluation = newValue;
    setScan(scan);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    onClose();
  };

  const handleConfirmCancel = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        onCancel={handleConfirmCancel}
        onConfirm={handleConfirmClose}
      />

      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        maxWidth={"sm"}
      >
        <Box>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" gutterBottom>
                Configure Scan:
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          component="form"
          m={2}
          sx={{
            "& .MuiTextField-root": { m: 1 },
            flexGrow: 1,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Scan Name"
            variant="outlined"
            size="small"
            inputRef={textFieldRef}
            required
            onChange={handleNameChange}
            defaultValue={getScan.Name}
            inputProps={{
              autoComplete: "off",
            }}
          />
          <CheckboxesTags
            selectedOptions={getScan.Universe}
            valueChanged={setNewUniverse}
          ></CheckboxesTags>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <List dense>
                {getScan.Formulas.map((f, index) => {
                  const formula = getFormulaById(Formulas, f.FormulaId);
                  return (
                    <ListItem key={index + "_" + f.FormulaId} disablePadding>
                      <ListItemButton
                        onClick={() => setSelectedFormulaId(f.FormulaId)}
                      >
                        <ListItemText primary={formula?.Name} />
                      </ListItemButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          onRemoveItem(f.FormulaId);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </ListItem>
                  );
                })}

                <ListItem disablePadding>
                  <ListItemButton onClick={AddFormulaClick}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add Condition" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              {getSelectedFormulaId.length > 0 && (
                <div key={getSelectedFormulaId}>
                  <TextField
                    id="scan-formula"
                    label="Formula"
                    variant="outlined"
                    size="small"
                    disabled
                    value={selectedFormula.Formula}
                  />
                  <TextField
                    id="scan-formula-eval"
                    label="Formula Evaluation"
                    variant="outlined"
                    size="small"
                    defaultValue={selectedScanFormula?.Evaluation}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFormulaChange(e)
                    }
                    inputProps={{
                      autoComplete: "off",
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
