import { IModule } from "../calculator/Modules";
import { XHelpers } from "../XHelpers";
import { YHelpers } from "../YHelpers";
import { PriceScaleRenderer } from ".";

export class BackGroundRenderer implements IModule {
  private yHelper: YHelpers;
  private xHelper: XHelpers;
  private symbol: string = "";
  private scaleRenderer: PriceScaleRenderer;
  private ctx: CanvasRenderingContext2D;

  constructor(
    ctx: CanvasRenderingContext2D,
    yHelper: YHelpers,
    xHelper: XHelpers
  ) {
    this.ctx = ctx;
    this.yHelper = yHelper;
    this.xHelper = xHelper;
    this.scaleRenderer = new PriceScaleRenderer(ctx, yHelper, xHelper);
  }

  calculate = () => {
    return;
  };

  SetSymbol(sym: string) {
    this.symbol = sym;
  }

  render = () => {
    this.scaleRenderer.DrawScale();
    this.WriteSymbol();
  };

  WriteSymbol = () => {
    if (!this.symbol || this.symbol.length == 0) {
      return;
    }
    const ctx = this.ctx;
    ctx.save();

    var text = this.symbol;
    ctx.font = "30px Arial"; // Change font size and style as needed
    var textMetrics = ctx.measureText(text);

    var startX = (this.xHelper.chartWidth - textMetrics.width) / 2;
    var startY = this.yHelper.areaHeight / 2 + 30 / 2; // 30 is approximately the font size

    ctx.globalAlpha = 0.2; // Adjust the transparency
    ctx.fillStyle = "blue"; // Change the color if needed
    ctx.fillText(text, startX, startY);

    ctx.restore();
  };
}
