import { Layout } from "react-grid-layout";

export const layoutArraysAreEqual = (
  layouts1: Layout[],
  layouts2: Layout[]
): boolean => {
  if (layouts1.length !== layouts2.length) {
    return false;
  }

  const sortByI = (a: Layout, b: Layout) => (a.i > b.i ? 1 : -1);

  const sortedLayouts1 = layouts1.slice().sort(sortByI);
  const sortedLayouts2 = layouts2.slice().sort(sortByI);

  for (let index = 0; index < sortedLayouts1.length; index++) {
    const layout1 = sortedLayouts1[index];
    const layout2 = sortedLayouts2[index];

    if (
      layout1.i !== layout2.i ||
      layout1.x !== layout2.x ||
      layout1.y !== layout2.y ||
      layout1.w !== layout2.w ||
      layout1.h !== layout2.h
    ) {
      return false;
    }
  }

  return true;
};

import { useAppStore, useSnackStore } from "../store";

export const addContainer = (
  selectedId: string | undefined,
  type: "watchList" | "scan" | "chart" | "worm",
  props?: any | undefined
) => {
  const getLayout = useAppStore.getState().ActiveLayout;
  if (!getLayout) {
    return;
  }

  const getNewId = (): string => {
    var id = 0;
    do {
      id++;
      var exists = getLayout.containers.find((f) => f.i == id.toString());
    } while (exists);
    return id.toString();
  };

  let width = 4;
  let height = 4;
  switch (type) {
    case "chart":
      width = 16;
      height = 8;
      break;
    case "scan":
    case "watchList":
      width = 4;
      break;
    case "worm":
      width = 16;
      break;
    default:
      width = 4;
  }

  const newContainer = {
    i: getNewId(),
    x: 0,
    y: Infinity,
    w: width,
    h: height,
    selectedId: selectedId,
    type: type,
    props: props,
  };
  const _cont = getLayout;
  _cont.containers = _cont.containers.concat(newContainer);
  useAppStore.setState({ ActiveLayout: _cont });

  useSnackStore.getState().SetSnackMessage("Added to layout");
};
