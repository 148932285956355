import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextMenu, TopMenu } from "./menus/";

import AddBoxIcon from "@mui/icons-material/AddBox";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useAppStore, useFormulaStore, useWatchList } from "./store";
import { addContainer } from "./helpers/layoutHelper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "./appRoutes";
import { AuthContext } from "./auth";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

export const AppHeader = () => {
  const elementRef = useRef<HTMLSpanElement>(null);

  const authContext = useContext(AuthContext);

  // Check if authContext is undefined (not wrapped in AuthProvider)
  if (!authContext) {
    return <div>Loading...</div>; // or handle this case as needed
  }

  const { isLoggedIn } = authContext;

  const ActiveSymbol = useAppStore((state) => state.ActiveSymbol);
  const watchLists = useWatchList((s) => s.watchLists);
  // const { SetFormulaModalOpen  = useFormulaStore((s)=>s.SetFormulaModalOpen);

  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setPosition({
          y: rect.top + window.scrollY + 10,
          x: rect.left + window.scrollX + 10,
        });
      }
    };

    getData();
  }, []);

  const addWatchList = () => {
    if (!watchLists || Object.keys(watchLists).length === 0) {
      navigate(APPROUTES.APP.ADD_WATCHLIST);
    } else {
      addContainer(
        watchLists[Object.keys(watchLists)[0]].id as string,
        "watchList"
      );
    }
  };

  return (
    <span className="app_header_menu">
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span style={{ display: "flex" }} ref={elementRef}>
          <AddBoxIcon
            fontSize="inherit"
            onClick={() => setShowContextMenu(true)}
          />
        </span>
        <ContextMenu
          open={showContextMenu}
          position={position}
          onClose={() => setShowContextMenu(false)}
        >
          <TopMenu
            addWatchList={addWatchList}
            onClose={() => {
              setShowContextMenu(false);
            }}
          ></TopMenu>
        </ContextMenu>
        {/* <FunctionsIcon
          fontSize="inherit"
          onClick={() => SetFormulaModalOpen(true)}
        /> */}
        {ActiveSymbol}
      </span>
      <span
        style={{ color: isLoggedIn ? "green" : "inherit" }}
        onClick={() =>
          !isLoggedIn
            ? navigate(APPROUTES.APP.LOGIN)
            : navigate(APPROUTES.APP.LOGOUT)
        }
      >
        {isLoggedIn ? (
          <AccountCircleIcon fontSize="small" />
        ) : (
          <NoAccountsIcon fontSize="small" />
        )}
      </span>
    </span>
  );
};
