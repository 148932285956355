import { IDataTypeHelper } from "../helpers/DataHelpers";
import { PointData, PointTypes } from "../interfaces/PointTypes";
import { Orders } from "../renderer/OrderRenderer";
import stockdata, { multiLineData } from "./data";

export function GetMultiLine() {
  return multiLineData();
}

export interface WormResult {
  [key: string]: NetHighLow;
}

export interface NetHighLow {
  Highs: number;
  Lows: number;
}

export const timeSeriesData: WormResult = {
  "2024-05-29T09:30:01.7344714-04:00": { Highs: 6167, Lows: 7875 },
  "2024-05-29T09:31:01.2368102-04:00": { Highs: 4868, Lows: 9096 },
  "2024-05-29T09:32:01.4764744-04:00": { Highs: 4825, Lows: 9151 },
  "2024-05-29T09:33:01.2657021-04:00": { Highs: 4803, Lows: 9179 },
  "2024-05-29T09:34:01.6742265-04:00": { Highs: 4696, Lows: 9291 },
  "2024-05-29T09:35:02.9835972-04:00": { Highs: 4649, Lows: 9348 },
  "2024-05-29T09:36:02.6747768-04:00": { Highs: 4630, Lows: 9371 },
  "2024-05-29T09:37:01.2376894-04:00": { Highs: 4605, Lows: 9390 },
  "2024-05-29T09:38:01.3027253-04:00": { Highs: 4613, Lows: 9396 },
  "2024-05-29T09:39:02.0876529-04:00": { Highs: 4610, Lows: 9403 },
  "2024-05-29T09:40:01.518203-04:00": { Highs: 4606, Lows: 9405 },
  "2024-05-29T09:41:01.9223523-04:00": { Highs: 4591, Lows: 9417 },
  "2024-05-29T09:42:01.5340909-04:00": { Highs: 4580, Lows: 9424 },
  "2024-05-29T09:43:01.9124436-04:00": { Highs: 4570, Lows: 9439 },
  "2024-05-29T09:44:01.6080843-04:00": { Highs: 4568, Lows: 9459 },
  "2024-05-29T09:45:01.5657614-04:00": { Highs: 4556, Lows: 9476 },
  "2024-05-29T09:46:01.6147663-04:00": { Highs: 4191, Lows: 9743 },
  "2024-05-29T09:47:01.4385927-04:00": { Highs: 4162, Lows: 9761 },
  "2024-05-29T09:48:01.8920704-04:00": { Highs: 4112, Lows: 9808 },
  "2024-05-29T09:49:02.6696179-04:00": { Highs: 4067, Lows: 9859 },
  "2024-05-29T09:50:01.6661597-04:00": { Highs: 4072, Lows: 9858 },
  "2024-05-29T09:51:01.4735789-04:00": { Highs: 4061, Lows: 9856 },
  "2024-05-29T09:52:01.7848979-04:00": { Highs: 4047, Lows: 9881 },
  "2024-05-29T09:53:01.772404-04:00": { Highs: 4052, Lows: 9881 },
  "2024-05-29T09:54:01.8692311-04:00": { Highs: 4039, Lows: 9875 },
  "2024-05-29T09:55:01.509871-04:00": { Highs: 4030, Lows: 9892 },
  "2024-05-29T09:56:01.8341543-04:00": { Highs: 4002, Lows: 9923 },
  "2024-05-29T09:57:01.3406965-04:00": { Highs: 3996, Lows: 9929 },
  "2024-05-29T09:58:01.7102215-04:00": { Highs: 3990, Lows: 9945 },
  "2024-05-29T09:59:02.1809773-04:00": { Highs: 3995, Lows: 9939 },
  "2024-05-29T10:00:02.0454332-04:00": { Highs: 4000, Lows: 9924 },
  "2024-05-29T10:01:01.274395-04:00": { Highs: 4001, Lows: 9921 },
  "2024-05-29T10:02:01.5430502-04:00": { Highs: 4021, Lows: 9907 },
  "2024-05-29T10:03:02.7878375-04:00": { Highs: 4043, Lows: 9889 },
  "2024-05-29T10:04:01.1922675-04:00": { Highs: 4024, Lows: 9908 },
  "2024-05-29T10:05:01.3985057-04:00": { Highs: 4019, Lows: 9906 },
  "2024-05-29T10:06:01.5424846-04:00": { Highs: 4030, Lows: 9907 },
  "2024-05-29T10:07:01.9554619-04:00": { Highs: 4045, Lows: 9884 },
  "2024-05-29T10:08:01.2929924-04:00": { Highs: 4049, Lows: 9893 },
  "2024-05-29T10:09:01.8858107-04:00": { Highs: 4029, Lows: 9913 },
  "2024-05-29T10:10:01.7624889-04:00": { Highs: 4045, Lows: 9894 },
  "2024-05-29T10:11:02.3212861-04:00": { Highs: 4036, Lows: 9902 },
  "2024-05-29T10:12:05.4749745-04:00": { Highs: 4066, Lows: 9885 },
  "2024-05-29T10:13:01.8588708-04:00": { Highs: 4062, Lows: 9895 },
  "2024-05-29T10:14:01.8928822-04:00": { Highs: 4072, Lows: 9882 },
  "2024-05-29T10:15:01.4871139-04:00": { Highs: 4055, Lows: 9890 },
  "2024-05-29T10:16:02.1629414-04:00": { Highs: 4083, Lows: 9879 },
  "2024-05-29T10:17:01.5127107-04:00": { Highs: 4084, Lows: 9873 },
  "2024-05-29T10:18:02.8710794-04:00": { Highs: 4060, Lows: 9904 },
  "2024-05-29T10:19:01.4027667-04:00": { Highs: 4053, Lows: 9904 },
  "2024-05-29T10:20:03.2320769-04:00": { Highs: 4061, Lows: 9904 },
  "2024-05-29T10:21:02.2170097-04:00": { Highs: 4057, Lows: 9904 },
  "2024-05-29T10:22:01.4773831-04:00": { Highs: 4036, Lows: 9923 },
  "2024-05-29T10:23:01.8384886-04:00": { Highs: 4018, Lows: 9944 },
  "2024-05-29T10:24:01.8489705-04:00": { Highs: 3985, Lows: 9986 },
  "2024-05-29T10:25:02.4498012-04:00": { Highs: 4033, Lows: 9938 },
  "2024-05-29T10:26:01.2629825-04:00": { Highs: 4060, Lows: 9902 },
  "2024-05-29T10:27:01.7978623-04:00": { Highs: 4055, Lows: 9913 },
  "2024-05-29T10:28:01.805034-04:00": { Highs: 4057, Lows: 9905 },
  "2024-05-29T10:29:01.4895036-04:00": { Highs: 4050, Lows: 9909 },
  "2024-05-29T10:30:02.8950142-04:00": { Highs: 4038, Lows: 9923 },
  "2024-05-29T10:31:01.5228616-04:00": { Highs: 4042, Lows: 9926 },
  "2024-05-29T10:32:01.8053912-04:00": { Highs: 4020, Lows: 9944 },
  "2024-05-29T10:33:01.3332345-04:00": { Highs: 4003, Lows: 9965 },
  "2024-05-29T10:34:01.6607004-04:00": { Highs: 3997, Lows: 9978 },
  "2024-05-29T10:35:03.0807089-04:00": { Highs: 3996, Lows: 9971 },
  "2024-05-29T10:36:01.7753198-04:00": { Highs: 3992, Lows: 9971 },
  "2024-05-29T10:37:01.7296097-04:00": { Highs: 4009, Lows: 9963 },
  "2024-05-29T10:38:01.3580216-04:00": { Highs: 4020, Lows: 9948 },
  "2024-05-29T10:39:01.5225822-04:00": { Highs: 4043, Lows: 9931 },
  "2024-05-29T10:40:02.6647649-04:00": { Highs: 4042, Lows: 9938 },
  "2024-05-29T10:41:01.9261227-04:00": { Highs: 4034, Lows: 9937 },
  "2024-05-29T10:42:02.7785883-04:00": { Highs: 4025, Lows: 9951 },
  "2024-05-29T10:43:02.7825301-04:00": { Highs: 3999, Lows: 9975 },
  "2024-05-29T10:44:01.6165829-04:00": { Highs: 3981, Lows: 9994 },
  "2024-05-29T10:45:01.6771048-04:00": { Highs: 3962, Lows: 10018 },
};

export const mapWormResultToPointData = (
  wormResult: WormResult
): PointData[][] => {
  const ret: PointData[] = [];
  const ret2: PointData[] = [];
  const ret3: PointData[] = [];
  const ret4: PointData[] = [];

  var prev: NetHighLow | null = null;
  for (const date in wormResult) {
    const netHighLow: NetHighLow = wormResult[date];
    if (prev === null) {
      prev = netHighLow;
    }
    const _date = new Date(date);
    ret.push({
      Val: netHighLow.Highs,
      Date: _date,
      Volume: 0,
    });
    ret2.push({
      Val: netHighLow.Lows,
      Date: _date,
      Volume: 0,
    });
    ret3.push({
      Val: netHighLow.Highs - prev.Highs,
      Date: _date,
      Volume: 0,
    });
    ret4.push({
      Val: netHighLow.Lows - prev.Lows,
      Date: _date,
      Volume: 0,
    });
    prev = netHighLow;
  }

  return [ret, ret2, ret3, ret4];
};

export default function GetDevData(
  helper: IDataTypeHelper<PointTypes>,
  isLine: boolean,
  liveUpdate: boolean,
  SetOrders: any,
  SetData: any
) {
  const rawData = stockdata(); //.slice(-2);

  var data: PointTypes[] | PointTypes[][] = [];

  if (isLine) {
    data = mapWormResultToPointData(timeSeriesData); // genLineMinute(140);
  } else {
    var dat = rawData
      .map((f) => ({
        Close: f.Close * 100,
        High: f.High * 100,
        Low: f.Low * 100,
        Open: f.Open * 100,
        Date: new Date(f.Date),
        Volume: f.Volume,
      }))
      .sort((a, b) => a.Date.getTime() - b.Date.getTime());

    data = [dat, dat];
  }

  const dt = data[0] as PointTypes[];

  if (!isLine) {
    const orders: Orders[] = [
      {
        type: "buy",
        shares: 50,
        price: helper.getCalcValue(dt[10]),
        time: dt[10].Date,
      },
      {
        type: "sell",
        shares: 30,
        price: helper.getCalcValue(dt[19]),
        time: dt[19].Date,
      },
      {
        type: "buy",
        shares: 150,
        price: helper.getCalcValue(dt[0]),
        time: dt[0].Date,
      },
    ];

    // SetOrders(orders);
  }

  let test_idx = 150;
  if (liveUpdate) {
    test_idx = 4;

    var timeOut = setInterval(() => {
      if (test_idx > rawData.length - 1) {
        clearInterval(timeOut);
        return;
      }
      const d = data.slice(0, test_idx);
      SetData(d);
      test_idx++;
    }, 2000);
  } else {
    const d = data.slice(0, test_idx);
    SetData(d);
  }

  const doThings = () => {
    const d = data.slice(0, test_idx);
    SetData(d);
    test_idx += 1;

    if (test_idx <= data.length - 1 && liveUpdate) {
      setTimeout(() => {
        doThings();
      }, 1000);
    }
  };

  doThings();
}
