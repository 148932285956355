import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APPROUTES } from "../appRoutes";
import { useSnackStore, useWatchList } from "../store/index";
import { add } from "lodash";

export interface CreateWatchlistProps {
  handleCreated: (newId: string) => void;
}

export const CreateWatchlist = ({ handleCreated }: CreateWatchlistProps) => {
  const [name, setname] = useState("");
  const createWatchList = useWatchList((s) => s.createWatchList);
  const navigate = useNavigate();
  const { addWindow } = useParams();

  const handleSave = async () => {
    const newId = await createWatchList({ name: name, symbols: [] });
    newId && !addWindow && handleCreated(newId);
    if (addWindow && addWindow === "false") {
      useSnackStore.getState().SetSnackMessage("Watchlist Created");
      handleClose();
    }
  };

  const handleClose = () => {
    navigate(APPROUTES.APP.ROOT);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              Create Watchlist
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <DialogContent>
        <Box>
          <TextField
            id="new-formula"
            label="Watchlist Name"
            size="small"
            required
            fullWidth
            rows={1}
            autoFocus={true}
            placeholder="Enter Name"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setname(e.target.value)
            }
            inputProps={{
              autoComplete: "off",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
