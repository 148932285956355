import React, { useEffect } from "react";
import App from "./app";
import { FormulaModal } from "./formula/formula-modal";
import { ScannerConfigModal } from "./scanner/";
import { useFormulaStore, useScanStore } from "./store/";

import "./appExtended.scss";
import { GetFormulaApi } from "./api";
import { useShallow } from "zustand/react/shallow";

export type ChartTimeframe = "1min" | "1day";

const WrapAppExtended = (BaseApp: any) => {
  const wrapped = (props: any) => {
    const [SetFormulaModalOpen, IsForumlaModalOpen, SetFormulas, Formulas] =
      useFormulaStore(
        useShallow((s) => [
          s.SetFormulaModalOpen,
          s.IsForumlaModalOpen,
          s.SetFormulas,
          s.Formulas,
        ])
      );

    const [SetScanEditingId, ScanEditingId, GetScanById, UpdateScan, GetScans] =
      useScanStore(
        useShallow((s) => [
          s.SetScanEditingId,
          s.ScanEditingId,
          s.GetScanById,
          s.UpdateScan,
          s.GetScans,
        ])
      );

    const getData = async () => {
      const res = await GetFormulaApi().getFormulas();
      SetFormulas(res as typeof Formulas);
    };

    useEffect(() => {
      getData();
    }, []);

    const handleScanConfigClose = () => {
      SetScanEditingId("");
    };

    // if (getLayout === null) {
    //   return null; // Ensure the component returns null if layout is not ready
    // }

    const addFormulaToScan = (FormulaId: string) => {
      let scan = GetScanById(ScanEditingId);
      scan.Formulas.push({ FormulaId, Evaluation: "" });
      UpdateScan(scan).then(() => {
        //TODO: anything here?
      });
    };

    const scanConfigOpen = ScanEditingId.length > 0;

    return (
      <>
        <FormulaModal
          AddToScan={addFormulaToScan}
          open={IsForumlaModalOpen}
          onClose={() => SetFormulaModalOpen(false)}
        />
        {GetScans && ScanEditingId && (
          <ScannerConfigModal
            key={ScanEditingId}
            open={scanConfigOpen}
            Scan={GetScans[ScanEditingId]}
            Formulas={Formulas}
            onClose={handleScanConfigClose}
          />
        )}

        <BaseApp {...props} />
      </>
    );
  };

  return wrapped;
};

export const AppExtended = WrapAppExtended(App);
