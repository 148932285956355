import React from "react";

interface AuthResultProps {
  error: string[] | null;
  success: string | null;
}

export const AuthResult: React.FC<AuthResultProps> = ({ error, success }) => {
  return (
    <>
      {error && (
        <div style={{ color: "red" }}>
          {error.map((errMsg, idx) => (
            <p key={idx}>{errMsg}</p>
          ))}
        </div>
      )}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </>
  );
};
