import debounce from "lodash/debounce";

export class DebouncedResizeObserver {
  private resizeObserver: ResizeObserver;
  private debouncedCallback: (entries: ResizeObserverEntry[]) => void;

  constructor(
    callback: (entries: ResizeObserverEntry[]) => void,
    wait: number = 300
  ) {
    this.debouncedCallback = debounce(callback, wait);
    this.resizeObserver = new ResizeObserver(this.debouncedCallback);
  }

  observe(element: HTMLElement): void {
    this.resizeObserver.observe(element);
  }

  unobserve(element: HTMLElement): void {
    this.resizeObserver.unobserve(element);
  }

  disconnect(): void {
    this.resizeObserver.disconnect();
  }
}
