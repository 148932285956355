import { Dev_FormulaApi } from "../api_dev/formula-api";
import { USE_LOCAL } from "../app";
import { FormulaItem, Formulas } from "../formula/formula-interfaces";
import axios from "./axios-wrapper";

const apiUrl = "/formula";

export interface IFormulaApi {
  getFormulas(): Promise<Formulas | undefined>;
  createFormulaItem(formulaItem: FormulaItem): Promise<string | undefined>;
}

export const GetFormulaApi = (): IFormulaApi => {
  if (USE_LOCAL) {
    return Dev_FormulaApi;
  }

  return FormulaApi;
};

export const FormulaApi: IFormulaApi = {
  getFormulas: async (): Promise<Formulas | undefined> => {
    try {
      const response = await axios.get<Formulas>(apiUrl);

      if (response.status === 200) {
        // Set the retrieved data to the state
        return response.data;
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },

  createFormulaItem: async (
    formulaItem: FormulaItem
  ): Promise<string | undefined> => {
    try {
      const resp = await axios.post(apiUrl, formulaItem);
      return resp.data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
};
