import React, { useContext, useEffect, useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import { WatchListComponent, WatchLists } from "./Lists";
import { ChartComponent, WormComponent } from "./chart";
import { LayoutContainer, LayoutItem, Layouts } from "./models/layoutModels";
import { ScannerComponent } from "./scanner/scanner-list";

import { GetLayoutApi, GetWatchListApi } from "./api/";
import {
  useAppStore,
  useScanStore,
  useSnackStore,
  useWatchList,
} from "./store/";

import { debounce } from "@mui/material";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Outlet } from "react-router-dom";
import "./app.scss";
import { AppHeader } from "./appHeader";
import { AuthContext } from "./auth";
import { layoutArraysAreEqual } from "./helpers/layoutHelper";
import { SnackBar } from "./shared/snackBar";
import { useShallow } from "zustand/react/shallow";

const componentMap: { [key: string]: React.ComponentType<any> } = {
  chart: ChartComponent,
  watchList: WatchListComponent,
  scan: ScannerComponent,
  worm: WormComponent,
};

export interface mappedCompProps {
  Id: string;
  remove: () => void;
  title: string;
  isActive: boolean;
  selectedId: string;
}

export const CHART_RESIZED_EVENT = "chartResized";

//TODO: for quicker local dev
export const USE_LOCAL = false;

const defaultLayout: LayoutItem = {
  containers: [
    {
      i: "chart",
      h: 6,
      w: 28,
      x: 0,
      y: 0,
      type: "chart",
      props: { ticker: "TSLA", timeFrame: "1day" },
    },
    {
      i: "scan",
      selectedId: "6_month_strongest",
      h: 6,
      w: 8,
      x: 28,
      y: 0,
      type: "scan",
      props: undefined,
    },
  ],
  description: "",
  name: "",
};

export const App = (props: any) => {
  const [firstChange, setFirstChange] = useState(false);
  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth);
  const authctx = useContext(AuthContext);

  const [UpdateScan, SetScans, GetScans] = useScanStore(
    useShallow((s) => [s.UpdateScan, s.SetScans, s.GetScans])
  );
  const SetWatchLists = useWatchList((s) => s.SetWatchLists);
  const SetSnackMessage = useSnackStore((s) => s.SetSnackMessage);
  const [
    ActiveWindow,
    UpsertLayout,
    SetActiveLayout,
    ActiveLayout,
    SetActiveWindow,
  ] = useAppStore(
    useShallow((s) => [
      s.ActiveWindow,
      s.UpsertLayout,
      s.SetActiveLayout,
      s.ActiveLayout,
      s.SetActiveWindow,
    ])
  );

  useEffect(() => {
    let ignore = false;
    const getData = async () => {
      var layout = defaultLayout;
      if (isLoggedIn) {
        const res = await Promise.all([
          GetWatchListApi().getWatchLists(),
          GetLayoutApi().getLayouts(),
        ]);

        SetWatchLists(res[0] as WatchLists);
        const layouts = res[1] as Layouts;
        const keys = Object.keys(layouts);
        const first = layouts[keys[0]];
        layout = first ?? defaultLayout;
      }
      !ignore && SetActiveLayout(layout);
    };

    authctx && getData();

    return () => {
      ignore = true;
    };
  }, [authctx]);

  useEffect(() => {
    setFirstChange(true);
    const handleResize = debounce(
      () => setWindowWidth(document.body.clientWidth),
      200
    );
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //raiseResized("");

    if (props.scans) {
      SetScans(props.scans);
    } else {
      SetScans({
        ["6_month_strongest"]: {
          Id: "6_month_strongest",
          Name: "6 Month Strongest",
          Universe: [],
          Description: "6 Month Strongest",
          Formulas: [],
        },
      });
    }
  }, [props.scans]);

  if (!authctx) {
    return;
  }

  const isLoggedIn = authctx.isLoggedIn;

  //todo.. only if chart is resized
  const raiseResized = (id: string) => {
    const event = new CustomEvent(CHART_RESIZED_EVENT, { detail: id });
    window.dispatchEvent(event);
  };

  const handleLayoutChange = (newLayout: Layout[]): void => {
    //TODO: Default layout?
    if (ActiveLayout === null) {
      return;
    }

    if (!firstChange) {
      setFirstChange(true);
      return;
    }

    if (layoutArraysAreEqual(ActiveLayout.containers, newLayout)) {
      return;
    }

    const updatedContainerStates = newLayout.map((layoutItem) => {
      // Find the corresponding original container state
      const originalContainerState = ActiveLayout.containers.find(
        (state) => state.i === layoutItem.i
      ) as LayoutContainer;

      return {
        ...layoutItem,
        type: originalContainerState.type,
        props: originalContainerState.props,
        selectedId: originalContainerState.selectedId,
      };
    });

    var _updateContainer = ActiveLayout;
    _updateContainer.containers = updatedContainerStates;
    UpsertLayout(_updateContainer, isLoggedIn);
  };

  const resizeStop: ReactGridLayout.ItemCallback = (
    layout,
    olditem,
    newItem
  ) => {
    raiseResized(newItem.i);
  };

  const handleRemove = (id: string) => {
    var _cont = ActiveLayout as LayoutItem;
    _cont.containers = _cont.containers.filter((f) => f.i != id);
    UpsertLayout(_cont, isLoggedIn);
    SetSnackMessage(
      `Container removed. ${isLoggedIn ? "" : "Must log in to save changes."}`,
      isLoggedIn ? "success" : "warning"
    );
  };

  if (!ActiveLayout) {
    return null;
  }

  return (
    <>
      <SnackBar />

      <AppHeader />
      <GridLayout
        className="layout"
        margin={[0, 0]}
        layout={ActiveLayout.containers}
        onLayoutChange={handleLayoutChange}
        isResizable={true}
        resizeHandles={["se"]}
        cols={36}
        rowHeight={75}
        width={windowWidth}
        draggableHandle=".draggable-handle"
        onResizeStop={resizeStop}
      >
        {ActiveLayout.containers.map((container, index) => {
          const ContainerComponent = componentMap[container.type];

          const isActive = container.i === ActiveWindow;

          return (
            <div
              key={container.i}
              style={{
                overflow: "none",
                border: "1px solid black",
                backgroundColor: isActive
                  ? "var(--background-layer1-active)"
                  : "",
              }}
              onClick={() =>
                (ContainerComponent == WatchListComponent ||
                  ContainerComponent == ScannerComponent) &&
                !isActive &&
                SetActiveWindow(container.i)
              }
            >
              {typeof ContainerComponent === "function" ? (
                <ContainerComponent
                  Id={container.i}
                  isActive={isActive}
                  props={container.props}
                  selectedId={container.selectedId}
                  remove={() => handleRemove(container.i)}
                />
              ) : (
                <div>Component Not Found</div>
              )}
            </div>
          );
        })}
      </GridLayout>
      <Outlet />
    </>
  );
};

export default App;
