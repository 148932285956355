import axios from "axios";
import { StockData } from "../../../WebChart/src/interfaces";

export const YahooAPI = {
  getYahooData: async (
    symbol: string,
    start: string,
    end: string,
    interval: string
  ) => {
    //interval like 1m or 5m
    // Convert start and end dates to Unix timestamps
    const period1 = Math.floor(new Date(start).getTime() / 1000);
    const period2 = Math.floor(new Date(end).getTime() / 1000);

    // Construct the URL
    // const url = `https://query1.finance.yahoo.com/v8/finance/chart/${symbol}?symbol=${symbol}&period1=${period1}&period2=${period2}&useYfid=true&interval=${interval}&includePrePost=true&events=div|split|earn&lang=en-US&region=US`;
    const url =
      "https://query1.finance.yahoo.com/v8/finance/chart/TSLA?period1=1705986000&period2=1706065179&interval=1d&includePrePost=true&events=div%7Csplit%7Cearn&useYfid=true&lang=en-US&region=US";

    try {
      const response = await axios.get(url);
      const f = JSON.parse(await response.data); // Assuming jsonData is a JSON string

      if (!f.chart.result[0].timestamp) {
        console.error(
          `failed from yahoo ticker:${symbol} start: ${start}, end ${end}`
        );
        throw "failed to get data";
      }

      const data = await processChartData(f);
      // Process the data as needed
      return data; // or process and return the specific data you need
    } catch (error) {
      console.error("Fetch error:", error);
      return null;
    }
  },
};

function processChartData(jsonData: any): StockData[] {
  const ret = [];

  for (let i = 0; i < jsonData.chart.result[0].timestamp.length; i++) {
    const time = jsonData.chart.result[0].timestamp[i];
    const rec = jsonData.chart.result[0].indicators.quote[0];

    if (!time || rec.volume[i] === null) continue;

    const ssdp: StockData = {
      Close: rec.close[i],
      High: rec.high[i],
      Low: rec.low[i],
      Open: rec.open[i],
      Volume: rec.volume[i],
      Date: new Date(time * 1000), // Convert Unix timestamp to local time
    };

    ret.push(ssdp);
  }

  return ret;
}
