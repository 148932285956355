import { IModule, ModuleProps } from "../calculator/Modules";
import { PointTypes, StockData } from "../interfaces/PointTypes";

export class CandleStick implements IModule {
  constructor(props: ModuleProps) {
    this.props = props;
  }
  private props: ModuleProps;

  calculate = () => {
    return;
  };

  RenderData(data: PointTypes[]) {
    for (
      let index = this.props.xHelper.maxDataIndex;
      index >= this.props.xHelper.minDataIndex;
      index--
    ) {
      const element = data[index] as StockData;

      var idxpix = this.props.xHelper.XIndexToPixel(index);
      var high = this.props.yHelper.NumYToPixel(element.High);
      var low = this.props.yHelper.NumYToPixel(element.Low);
      var open = this.props.yHelper.NumYToPixel(element.Open);
      var close = this.props.yHelper.NumYToPixel(element.Close);

      this.DrawCandle(
        high,
        open,
        close,
        low,
        idxpix,
        this.props.xHelper.elementSize
      );
    }
  }

  render = () => {
    const data = this.props.dataMan.GetData();
    this.RenderData(data);
  };

  private DrawCandle(
    high: number,
    open: number,
    close: number,
    low: number,
    xPosition: number,
    barSize: number
  ) {
    //values are positions not price so axis is top down
    const ctx = this.props.ctx;
    ctx.save();
    ctx.fillStyle = "green";
    if (close > open) {
      ctx.fillStyle = "red";
    }

    const barLowPixel = Math.max(open, close);
    const barHighPixel = Math.min(open, close);
    const height = Math.abs(open - close);

    var middlePosition = xPosition + barSize / 2;

    //body
    ctx.fillRect(xPosition, barHighPixel, barSize, height);

    //top
    ctx.beginPath();
    ctx.moveTo(middlePosition, high);
    ctx.lineTo(middlePosition, barHighPixel);
    ctx.stroke();

    //bottom
    ctx.beginPath();
    ctx.moveTo(middlePosition, low);
    ctx.lineTo(middlePosition, barLowPixel);
    ctx.stroke();

    ctx.restore();
  }
}
