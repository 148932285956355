import React, { useEffect, useState } from "react";
import { Chart } from "../../../WebChart/src/Chart";
import {
  WormResult,
  mapWormResultToPointData,
} from "../../../WebChart/src/_forDev/devData";
import { WormApi } from "../api/worm-api";
import { CHART_RESIZED_EVENT } from "../app";
import { MenuHeader } from "../Lists";

export interface WormProps {
  remove: () => void;
}

export const WormComponent = ({ remove }: WormProps) => {
  const [myChart, setChart] = useState<Chart | null>(null);

  const Id = "worm-chart";

  const getData = async () => {
    await WormApi.getWorm().then((data) => {
      const dt = mapWormResultToPointData(data as WormResult);
      myChart?.SetData(dt);
    });
  };

  useEffect(() => {
    if (!myChart) {
      return;
    }
    myChart.DebounceResize();
    getData();
  }, [myChart]);

  useEffect(() => {
    // When the component mounts, create the chart instance
    const _chart = new Chart(Id, {
      showLegend: true,
      timePeriod: "IntraDay",
      elementSpacing: 2,
      elementSize: 10,
    });

    var ca = _chart.AddArea(100);
    ca.AddPlot({
      color: "green",
      type: "Line",
    });

    ca.AddPlot({
      color: "red",
      type: "Line",
    });

    ca.AddPlot({
      color: "orange",
      type: "Line",
      scalePosition: "Left",
    });

    ca.AddPlot({
      color: "blue",
      type: "Line",
      scalePosition: "Left",
    });

    setChart(_chart);

    const handleChartResized = (e: any) => {
      _chart.DebounceResize();
    };

    window.addEventListener(CHART_RESIZED_EVENT, handleChartResized);

    return () => {
      setChart(null);
      window.removeEventListener(CHART_RESIZED_EVENT, handleChartResized);
    };
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ width: "100%" }}>
        <MenuHeader
          refresh={getData}
          type="chart"
          remove={remove}
          containerId={Id}
        />
      </div>
      <div
        id={Id}
        style={{ flexGrow: 1 }}
        // onMouseEnter={() => setIsMouseOver(true)}
        // onMouseLeave={() => setIsMouseOver(false)}
      ></div>
    </div>
  );
};
