// @ts-ignore
import { Area } from "../Area";
import { XHelpers } from "../XHelpers";
import { YHelpers } from "../YHelpers";
import { MenuActions, Point } from "../interfaces";
import { MenuRenderer } from "../renderer";
import { IMouseEvents } from "../interfaces/MouseEvents";

export class MouseManager {
  private menu: MenuRenderer;
  private masterRenderAll: () => void;
  private xHelper: XHelpers;
  private canvas: HTMLCanvasElement;
  private overlayRenderAll: () => void;
  private getChartArea: (mousePoint: Point) => Area | null;

  private isOnPrice = false;
  private isOnLeftPrice = false;
  isScalingPrice = false;
  private drawables: IMouseEvents[] = [];
  startY = 0;
  startX = 0;
  mouseMoveTimer: null | ReturnType<typeof setTimeout> = null;

  isDragging: boolean;
  isScrollDrag: boolean;
  MousePosClient: Point;

  wasAlt: boolean;

  constructor(
    xHelper: XHelpers,
    canvas: HTMLCanvasElement,
    menu: MenuRenderer,
    getChartArea: (mousePoint: Point) => Area | null,
    masterRenderAll: () => void,
    overlayRenderAll: () => void
  ) {
    this.isDragging = false;
    this.isScrollDrag = false;
    this.wasAlt = false;
    this.xHelper = xHelper;
    this.MousePosClient = { x: 0, y: 0 };

    // this.yHelper = yHelper;
    this.menu = menu;
    this.canvas = canvas;
    this.overlayRenderAll = overlayRenderAll;
    this.masterRenderAll = masterRenderAll;
    this.getChartArea = getChartArea;

    window.addEventListener("scroll", function (event) {
      // Prevent the default scroll behavior
      event.preventDefault();
    });

    window.addEventListener("keydown", (e) => {
      //todo delay timer?
      this.handleKeyDown(e);
    });

    canvas.addEventListener("mousemove", (e) => {
      this.mouseMove(e);
    });
    canvas.addEventListener("mousedown", (e) => this.mouseDown(e));

    canvas.addEventListener("mouseup", (e) => this.mouseUp(e));
    canvas.addEventListener("mouseleave", () => this.handleMouseLeave());

    canvas.addEventListener("contextmenu", (e) => this.menu.rightClick(e));
    canvas.addEventListener("wheel", (e) => this.handleMouseScroll(e));

    if ("ontouchstart" in window) {
      // Add event listeners for touch events
      canvas.addEventListener("touchstart", (e) => this.handleTouchStart(e));
      canvas.addEventListener("touchmove", (e) => this.handleTouchMove(e));
      canvas.addEventListener("touchend", () => this.handleTouchEnd);
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.code === "Space") {
      e.preventDefault();
      return;
    }

    const wasAlt = e.key === "Alt";
    if (wasAlt || !this.wasAlt) {
      this.wasAlt = wasAlt;
      return;
    }

    var action = this.menu.mainMenuItems.find((f) => f.shortCut === e.key);
    if (action) {
      action.event();
    }

    this.wasAlt = false;
  }

  addMouseEvents(renderer: IMouseEvents) {
    this.drawables.push(renderer);
  }

  handleMouseLeave = () => {
    document.body.style.cursor = "default";
  };

  handleMouseScroll = (event: WheelEvent) => {
    event.preventDefault();
    const zoomSpeed = 0.1;
    const zoomIntensity = event.deltaY > 0 ? 1 + zoomSpeed : 1 - zoomSpeed;
    const curZoom = this.xHelper.zoomFactorX;

    const mousePositionX =
      event.clientX - this.canvas.getBoundingClientRect().left;
    const canvasCenterX = this.canvas.width / 2;
    const offsetXBeforeZoom = (mousePositionX - canvasCenterX) / curZoom;

    const zoomF = curZoom * zoomIntensity;

    const offsetXAfterZoom = (mousePositionX - canvasCenterX) / curZoom;
    const newOffsetX =
      this.xHelper.offsetX + offsetXBeforeZoom - offsetXAfterZoom;

    this.xHelper.SetZoom(zoomF);
    this.xHelper.SetOffsetX(newOffsetX);

    //todo: move this to chartMan?
    this.masterRenderAll();
  };

  mouseUp = (event: MouseEvent) => {
    this.drawables.forEach((element) => {
      element.onMouseUp && element.onMouseUp(event);
    });

    this.clearInProgressMouseAction();
  };

  clearInProgressMouseAction = () => {
    this.isDragging = false;
    this.isScrollDrag = false;
    this.isScalingPrice = false;
  };

  // Handle touch start event for panning and horizontal zooming
  handleTouchStart(event: TouchEvent) {
    event.preventDefault();
    this.startX = event.touches[0].clientX;
  }

  // Handle touch move event for panning and horizontal zooming
  handleTouchMove(event: TouchEvent) {
    event.preventDefault();
    const panDistanceX = event.touches[0].clientX - this.startX;
    const panDistanceY = event.touches[0].clientY - this.startY;

    // Determine the dominant direction (pan or zoom) based on absolute values
    const absPanDistanceX = Math.abs(panDistanceX);
    const absPanDistanceY = Math.abs(panDistanceY);

    if (absPanDistanceX > absPanDistanceY) {
      // Pan horizontally
      const offSetx = this.xHelper.offsetX + panDistanceX;
      this.xHelper.SetOffsetX(offSetx);

      this.startX = event.touches[0].clientX;
    } else {
      // Zoom in or out based on the y-direction of touch move
      const scale = event.touches[0].clientY > this.startY ? 0.9 : 1.1; // Zoom in or out based on the y-direction of touch move
      const zoom = this.xHelper.zoomFactorX * scale;
      this.xHelper.SetZoom(zoom);
      this.startY = event.touches[0].clientY;
    }

    this.masterRenderAll();
  }

  // Handle touch end event for panning and horizontal zooming
  handleTouchEnd = () => {};

  mouseDown(e: MouseEvent) {
    this.drawables.forEach((element) => {
      element.onMouseDown && element.onMouseDown(e);
    });
    const action = this.menu.selectedMenuAction;

    if (this.isOnPrice || this.isOnLeftPrice) {
      this.isScalingPrice = true;
      this.startY = e.clientY;
      return;
    }

    if (action == MenuActions.PanAndZoom) {
      this.isDragging = true;
      document.body.style.cursor =
        "url('../src/static_assets/png/icons8-hand-drag-16.png') 0 0, auto";
      this.startX = e.clientX;
    }
  }

  scrollMouseDown(e: MouseEvent) {
    this.isScrollDrag = true;
    this.startX = e.clientX;
  }

  scrollMouseMove(event: MouseEvent) {
    if (this.isScrollDrag) {
      const deltaX = event.clientX - this.startX;
      const newOffsetX = this.xHelper.offsetX - deltaX;

      this.xHelper.SetOffsetX(newOffsetX);
      this.startX = event.clientX;

      this.masterRenderAll();
    }
  }

  mouseMove(event: MouseEvent) {
    this.drawables.forEach((element) => {
      element.onMouseMove && element.onMouseMove(event);
    });

    const rect = this.canvas.getBoundingClientRect();

    let scaleX = this.canvas.width / this.canvas.clientWidth;
    let scaleY = this.canvas.height / this.canvas.clientHeight;

    const mouseX = (event.clientX - rect.left) * scaleX;
    const mouseY = (event.clientY - rect.top) * scaleY;

    const mousePosClient = (this.MousePosClient = { x: mouseX, y: mouseY });

    this.isOnPrice = this.xHelper.IsOnRightScale(mousePosClient.x);
    this.isOnLeftPrice = this.xHelper.IsOnLeftScale(mousePosClient.x);

    if (this.isOnLeftPrice || this.isOnPrice) {
      document.body.style.cursor = "ns-resize";
    } else if (this.isDragging) {
    } else {
      document.body.style.cursor = "default";
    }

    if ((this.isOnPrice || this.isOnLeftPrice) && this.isScalingPrice) {
      var area = this.getChartArea(mousePosClient);

      if (area) {
        let _yHelper: YHelpers = this.isOnLeftPrice
          ? area.yHelperLeft
          : area.yHelper;
        const deltaY = (event.clientY - this.startY) * 0.2;
        const newOffsetY = _yHelper.areaProps.offsetY + deltaY;
        _yHelper.SetOffsetY(newOffsetY);
      }

      this.startY = event.clientY;
      this.masterRenderAll();
      return;
    } else if (
      this.menu.selectedMenuAction === MenuActions.PanAndZoom &&
      this.isDragging
    ) {
      const deltaX = event.clientX - this.startX;
      const newOffsetX = this.xHelper.offsetX + deltaX;
      this.xHelper.SetOffsetX(newOffsetX);
      this.startX = event.clientX;

      this.masterRenderAll();
      return;
    }

    this.overlayRenderAll();
  }
}
