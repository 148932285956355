import { LineData } from "../interfaces/LineData";

export type CallbackAction = "LineDrawn" | "LineRemoved" | "GetLegend";

// Define the possible callback interfaces
export interface LineDrawnCallbackData {
  lineId: number;
  color: string;
}

export interface LineRemovedCallbackData {
  lineId: number;
}

export type CallbackData = LineData | LineRemovedCallbackData | any;

export class CallbackManager {
  private callbacks: { [action: string]: ((data: CallbackData) => void)[] } =
    {};

  // Register a callback for a specific action
  registerCallback = (
    action: CallbackAction,
    callback: (data: CallbackData) => void
  ): void => {
    if (typeof callback === "function") {
      if (!this.callbacks[action]) {
        this.callbacks[action] = [];
      }
      this.callbacks[action].push(callback);
    } else {
      throw new Error("Callback must be a function.");
    }
  };

  // Call registered callbacks for a specific action with provided data
  callCallbacks = (action: CallbackAction, data: CallbackData): void => {
    const actionCallbacks = this.callbacks[action] || [];
    actionCallbacks.forEach((callback) => {
      callback(data);
    });
  };

  // Remove a specific callback for a specific action
  removeCallback = (
    action: CallbackAction,
    callbackToRemove: (data: CallbackData) => void
  ): void => {
    const actionCallbacks = this.callbacks[action] || [];
    const index = actionCallbacks.indexOf(callbackToRemove);
    if (index !== -1) {
      actionCallbacks.splice(index, 1);
    }
  };

  // Clear all callbacks for a specific action
  clearCallbacks = (action: CallbackAction): void => {
    if (this.callbacks[action]) {
      this.callbacks[action] = [];
    }
  };
}

// // Example usage:

// const callbackManager = new CallbackManager();

// // // Register callbacks for specific actions with different interfaces
// const lineDrawnCallback = (data: LineDrawnCallbackData) => {
//   console.log(`Line Drawn - Line ID: ${data.lineId}, Color: ${data.color}`);
// };

// // const lineRemovedCallback = (data: LineRemovedCallbackData) => {
// //   console.log(`Line Removed - Line ID: ${data.lineId}`);
// // };

// // callbackManager.registerCallback('LineDrawn', lineDrawnCallback as (data: CallbackData) => void);
// // callbackManager.registerCallback('LineRemoved', lineRemovedCallback as (data: CallbackData) => void);

// // // Call registered callbacks for specific actions with different interfaces
// const lineDrawnData: LineDrawnCallbackData = { lineId: 1, color: "blue" };
// // const lineRemovedData: LineRemovedCallbackData = { lineId: 2 };

// callbackManager.callCallbacks("LineDrawn", lineDrawnData);
// callbackManager.callCallbacks("LineRemoved", lineRemovedData);

// // Remove a callback for a specific action
// callbackManager.removeCallback('LineDrawn', lineDrawnCallback as (data: CallbackData) => void);

// // Call remaining callbacks for specific actions with different interfaces
// callbackManager.callCallbacks('LineDrawn', lineDrawnData);
// callbackManager.callCallbacks('LineRemoved', lineRemovedData);

// // Clear all callbacks for a specific action
// callbackManager.clearCallbacks('LineRemoved');
