import { Area } from "../Area";
import { IModule } from "../calculator/Modules";
import { ChartConfig, TimePeriod } from "../Chart";
import { formatNumber, formatDateToMMDDYY } from "../helpers";
import { Point, PointTypes, MenuActions } from "../interfaces";
import { CallbackManager, MouseManager } from "../managers";
import { XHelpers } from "../XHelpers";
import { YHelpers } from "../YHelpers";
import { MenuRenderer } from ".";

export class CrosshairRenderer implements IModule {
  private areas: Area[];
  private xHelper: XHelpers;
  private callBackMan: CallbackManager;
  private showLegend: boolean;
  private timePeriod: TimePeriod = "IntraDay";
  private ctx: CanvasRenderingContext2D;
  private mouseMan: MouseManager;

  constructor(
    ctx: CanvasRenderingContext2D,
    xHelper: XHelpers,
    areas: Area[],
    menu: MenuRenderer,
    callBackMan: CallbackManager,
    config: ChartConfig,
    mouseMan: MouseManager
  ) {
    this.ctx = ctx;
    this.areas = areas;
    this.xHelper = xHelper;
    this.callBackMan = callBackMan;
    this.showLegend = config.showLegend;
    this.timePeriod = config.timePeriod;
    this.mouseMan = mouseMan;
  }

  SetTimePeriod = (timePeriod: TimePeriod) => {
    this.timePeriod = timePeriod;
  };

  calculate = () => {
    return;
  };

  private point: PointTypes | PointTypes[] | null = null;

  render = () => {
    const mousePos = this.mouseMan.MousePosClient;
    let point = null;

    for (let index = 0; index < this.areas.length; index++) {
      const area = this.areas[index];
      const yHelp = area.yHelper;

      if (!yHelp.IsPointInChartArea(mousePos)) {
        continue;
      }

      point = this.GetMousePoint(area);

      if (point) {
        if (this.showLegend) {
          this.WriteLegend(area, point);
        }
        this.WriteTime(point, yHelp);
      }

      this.DrawCrossHair(mousePos, yHelp);
      this.WritePrice(mousePos.y, yHelp);

      if (point != this.point) {
        this.callBackMan.callCallbacks("GetLegend", point);
        this.point = point;
      }
    }
    if (!point) {
      this.callBackMan.callCallbacks("GetLegend", null);
    }
  };

  private DrawCrossHair(mousePos: Point, yHelper: YHelpers) {
    const ctx = this.ctx;

    let x = mousePos.x;
    let y = mousePos.y;

    // // remove aliasing
    x = Math.floor(x) + 0.5;
    y = Math.floor(y) + 0.5;

    const crossHairSize = 10;

    //solid
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x, y - crossHairSize);
    ctx.lineTo(x, y + crossHairSize);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(x - crossHairSize, y);
    ctx.lineTo(x + crossHairSize, y);
    ctx.stroke();

    ctx.lineWidth = 1;
    //dashed
    ctx.beginPath();
    ctx.setLineDash([8, 10]);
    ctx.moveTo(x, yHelper.areaProps.topLeft.y);
    ctx.lineTo(x, yHelper.areaHeight + yHelper.areaProps.topLeft.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(yHelper.areaProps.topLeft.x, y);
    ctx.lineTo(this.xHelper.chartWidth + yHelper.areaProps.topLeft.y, y);
    ctx.stroke();

    ctx.setLineDash([]);
  }

  private GetMousePoint = (area: Area): PointTypes | PointTypes[] | null => {
    const mousePos = this.mouseMan.MousePosClient;
    const itemIndex = this.xHelper.PixelToIndex(mousePos.x);

    var data = area.plots[0].data.GetData();
    if (Array.isArray(data[0])) {
      data = data[0];
    }
    const point = data[this.xHelper.minDataIndex + itemIndex];
    // this.canvasMan.chartXAspect.visibleData[itemIndex];

    if (!point) {
      return null;
    }
    return point;
  };

  private WriteLegend = (
    area: Area,
    point: PointTypes | PointTypes[]
  ): void => {
    this.ctx.save();
    this.ctx.font = "12px serif";
    //TODO LEGEND
    var legend = area.plots[0].dataHelper.getLegend(point);
    this.ctx.fillText(legend, 2, 14);
    this.ctx.restore();
  };

  private WriteTime(
    pointParm: PointTypes | PointTypes[],
    yHelper: YHelpers
  ): void {
    var point = Array.isArray(pointParm) ? pointParm[0] : pointParm;
    //write time
    const mousePos = this.mouseMan.MousePosClient;
    const dtime = point.Date;
    if (dtime) {
      var dt: string = "";
      switch (this.timePeriod) {
        case "Daily":
          dt = formatDateToMMDDYY(dtime);
          break;
        case "IntraDay":
          dt = dtime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          break;

        default:
          break;
      }

      const height = yHelper.areaHeight;

      var yPos = yHelper.areaProps.topLeft.y + height;
      if (yPos === height) {
        yPos -= 30;
      }
      this.drawRoundedBoxWithText(dt, {
        x: mousePos.x - 30,
        y: yPos,
      });
    }
  }

  private WritePrice(mouseY: number, yHelper: YHelpers) {
    const price = yHelper.PixelYToNum(mouseY);

    const xPos = yHelper.areaProps.topLeft.x + this.xHelper.chartWidth;
    const y = Math.floor(mouseY) + 0.5;

    this.drawRoundedBoxWithText(formatNumber(price), {
      x: xPos,
      y: y - 10,
    });
  }

  private drawRoundedBoxWithText = (
    text: string,
    position: { x: number; y: number }
  ) => {
    // Define the properties
    const borderRadius: number = 10;
    const boxColor: string = "#000";
    const textColor: string = "#fff";
    const fontFamily: string = "Arial";
    const fontSize: number = 12;

    const ctx = this.ctx;
    ctx.save();
    // Set the font style
    ctx.font = `${fontSize}px ${fontFamily}`;

    // Calculate text width and height
    const textWidth: number = ctx.measureText(text).width;
    const textHeight: number = fontSize; // Approximation

    // Calculate the overall box dimensions
    const boxWidth: number = textWidth + 7; // arbitrary padding
    const boxHeight: number = textHeight + 10; // arbitrary padding

    // Draw the rounded rectangle
    ctx.fillStyle = boxColor;
    ctx.beginPath();
    ctx.moveTo(position.x + borderRadius, position.y);
    ctx.arcTo(
      position.x + boxWidth,
      position.y,
      position.x + boxWidth,
      position.y + boxHeight,
      borderRadius
    );
    ctx.arcTo(
      position.x + boxWidth,
      position.y + boxHeight,
      position.x,
      position.y + boxHeight,
      borderRadius
    );
    ctx.arcTo(
      position.x,
      position.y + boxHeight,
      position.x,
      position.y,
      borderRadius
    );
    ctx.arcTo(
      position.x,
      position.y,
      position.x + boxWidth,
      position.y,
      borderRadius
    );
    ctx.closePath();
    ctx.fill();

    // Draw the text inside
    ctx.fillStyle = textColor;
    ctx.fillText(text, position.x + 5, position.y + fontSize + 3); // adjusting y by fontSize for baseline and 15px for vertical centering
    ctx.restore();
  };
}
