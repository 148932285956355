import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import * as React from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CheckBoxTagProps {
  selectedOptions: number[];
  valueChanged: (values: number[]) => void;
}

export default function CheckboxesTags({
  selectedOptions,
  valueChanged,
}: CheckBoxTagProps) {
  const selectedValues = top100Films.filter((film) =>
    selectedOptions.includes(film.id)
  );

  const handleChange = (
    event: React.SyntheticEvent,
    value: any,
    reason: string
  ) => {
    const vals = value.map((f: any) => {
      return f.id;
    });
    valueChanged(vals);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={top100Films}
      disableCloseOnSelect
      limitTags={3}
      getOptionLabel={(option) => option.title}
      size="small"
      onChange={handleChange}
      defaultValue={selectedValues}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Universe" placeholder="Type to Search" />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { id: 0, title: "The Shawshank Redemption", year: 1994 },
  { id: 1, title: "The Godfather", year: 1972 },
  { id: 2, title: "The Godfather: Part II", year: 1974 },
  { id: 3, title: "The Dark Knight", year: 2008 },
  { id: 4, title: "12 Angry Men", year: 1957 },
  { id: 5, title: "Schindler's List", year: 1993 },
  { id: 6, title: "Pulp Fiction", year: 1994 },
];
