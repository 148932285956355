export function RoundTwo(val: number): number {
  return RoundToDecimalPlaces(val, 2);
}

export function RoundToDecimalPlaces(num: number, places: number): number {
  const factor = Math.pow(10, places);
  return Math.round((num + Number.EPSILON) * factor) / factor;
}

export function formatNumber(value: number): string {
  if (Math.abs(value) >= 1) {
    const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
    const suffixIndex = Math.floor(Math.log10(Math.abs(value)) / 3);
    const roundedValue = value / Math.pow(10, suffixIndex * 3);
    var formattedValue: string;
    if (value > 100000) {
      formattedValue = roundedValue.toFixed(0);
    } else if (value < 10) {
      formattedValue = roundedValue.toFixed(2); // Adjust the number of decimal places as needed
    } else {
      formattedValue = roundedValue.toFixed(1);
    }

    return formattedValue + suffixes[suffixIndex];
  } else {
    // For small numbers, just return the value with a fixed number of decimal places
    return value.toFixed(2);
  }
}
