import { Symbols } from "../Lists/symbol-list-interface";
import { USE_LOCAL } from "../app";
import axios from "./axios-wrapper";

const apiUrl = "/scanresult";

export interface IScanResultapi {
  getScanResult(scanId: string): Promise<Symbols[] | undefined>;
}

export const GetScanResultApi = (): IScanResultapi => {
  if (USE_LOCAL) {
    throw new Error("Not implemented");
    // return Dev_WatchListApi;
  }

  return ScanResultApi;
};

export const ScanResultApi: IScanResultapi = {
  getScanResult: async (scanId: string): Promise<Symbols[] | undefined> => {
    try {
      const response = await axios.get<Symbols[]>(apiUrl + "/" + scanId);

      if (response.status === 200) {
        // Set the retrieved data to the state
        return response.data;
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
};
