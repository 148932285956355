import React, { useState, FormEvent } from "react";
import axios, { axios as baseAxios } from "../api/axios-wrapper";
import { Button, TextField, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AuthModal } from "./authModal";
import { AuthResult } from "./authResult";

export const Register: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string[] | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Clear previous errors and success messages
    setError(null);
    setSuccess(null);

    // Check if passwords match
    if (password !== confirmPassword) {
      setError(["Passwords do not match."]);
      return;
    }

    try {
      // Send sign-up request to the server
      const response = await axios.post("auth/register", {
        email,
        password,
      });

      if (response.status === 200) {
        setSuccess(
          "Account created successfully. Please check your email to verify your account."
        );
      }
    } catch (err) {
      if (baseAxios.isAxiosError(err) && err.response?.status === 400) {
        // Map the error response to extract error descriptions
        const errorMessages = err.response.data.map(
          (error: { description: string }) => error.description
        );

        // Set the error state to display the error messages
        setError(errorMessages);
      } else {
        setError(["An error occurred. Please try again later."]);
      }
    }
  };

  return (
    <AuthModal>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography variant="h5">Register</Typography>

          <TextField
            type="email"
            label="Email"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={success !== null}
          />
          <TextField
            type="password"
            label="Password"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={success !== null}
          />
          <TextField
            type="password"
            label="Confirm Password"
            size="small"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={success !== null}
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            startIcon={<PersonAddIcon />}
            disabled={success !== null}
          >
            Sign Up
          </Button>
          <AuthResult error={error} success={success} />
        </div>
      </form>
    </AuthModal>
  );
};
