import { IModule } from "../calculator/Modules";
import { formatNumber } from "../helpers/PriceHelper";
import { XHelpers } from "../XHelpers";
import { YHelpers } from "../YHelpers";

//TODO should this be imodule and put with rest of renderes
export class PriceScaleRenderer implements IModule {
  private yHelper: YHelpers;
  private xHelper: XHelpers;
  private ctx: CanvasRenderingContext2D;
  private side: "Left" | "Right";

  constructor(
    ctx: CanvasRenderingContext2D,
    yHelper: YHelpers,
    xHelper: XHelpers,
    side?: "Left" | "Right"
  ) {
    this.xHelper = xHelper;
    this.ctx = ctx;
    this.yHelper = yHelper;
    this.side = side || "Right";
  }

  calculate = (): void => {};
  render = (): void => {
    this.DrawScale();
  };

  private scale = 50;

  CalcRange = (): number[] => {
    var filledRange: number[] = [];

    //TODO need to expand this for low priced stocks
    const range = [0.001, 0.0025, 0.005, 0.0075];
    const maxLimit =
      this.yHelper.areaProps.maxHigh - this.yHelper.areaProps.minLow;

    range.forEach((element) => {
      let currentValue = element;
      do {
        filledRange.push(currentValue);
        currentValue *= 10;
      } while (currentValue < maxLimit);
    });

    return filledRange.sort((a, b) => a - b);
  };

  //TODO: wtf is distance and what does it represent.
  //we need a better way to calculate number of elements
  DrawScale = () => {
    const distance = this.scale;
    const isLeft = this.side === "Left";
    let position = this.xHelper.chartWidth + this.yHelper.areaProps.topLeft.x;
    if (isLeft) {
      position = 10;
    }
    const numTics = Math.floor(this.yHelper.areaHeight / distance);
    var tics = this.generateSpecificPriceTicks(numTics, this.CalcRange());

    if (isLeft) {
      this.ctx.strokeStyle = "purple";
      this.ctx.fillStyle = "purple";
    } else {
      this.ctx.strokeStyle = "black";
      this.ctx.fillStyle = "black";
    }
    this.ctx.save();
    this.ctx.globalAlpha = 0.2;

    var tickValues: any[] = [];

    tics.forEach((f) => {
      var pos = this.yHelper.NumYToPixel(f);
      tickValues.push({
        tickValue: f,
        position: pos,
      });
    });

    //draw grid
    tickValues.forEach((val) => {
      this.ctx.beginPath();
      var startLeft = position - 5;
      if (isLeft) {
        startLeft = position + 45;
      }
      this.ctx.moveTo(startLeft, val.position);
      var leftPos = this.yHelper.areaProps.topLeft.x;
      if (isLeft) {
        leftPos = this.xHelper.chartWidth + 45;
      }
      this.ctx.lineTo(leftPos, val.position); // Adjust the length of the tick marks as needed
      this.ctx.stroke();
    });

    this.ctx.restore();

    this.ctx.save();
    this.ctx.font = "12px Arial"; // Set the font for labels

    //grid label
    tickValues.forEach((val) => {
      var num = formatNumber(val.tickValue);
      this.ctx.fillText(num, position, val.position + 4); // Label the ticks with 2 decimal places
    });
    this.ctx.restore();
  };

  private generateSpecificPriceTicks = (
    numberOfTicks: number,
    possibleTickValues: number[]
  ): number[] => {
    const maxPrice = this.yHelper.areaProps.maxHigh;
    const minPrice = this.yHelper.areaProps.minLow;

    const range =
      this.yHelper.areaProps.maxHigh - this.yHelper.areaProps.minLow;

    // Find the appropriate tick interval
    let tickInterval = possibleTickValues[possibleTickValues.length - 1];
    for (let i = possibleTickValues.length - 1; i >= 0; i--) {
      if (Math.ceil(range / possibleTickValues[i]) <= numberOfTicks) {
        tickInterval = possibleTickValues[i];
      } else {
        break;
      }
    }

    // Align the starting point to the tick interval
    const startValue = Math.ceil(minPrice / tickInterval) * tickInterval;

    // Generate the ticks
    const ticks: number[] = [];
    for (let i = 0; i < numberOfTicks; i++) {
      const tickValue = startValue + tickInterval * i;
      if (tickValue <= maxPrice) {
        ticks.push(tickValue);
      }
    }

    return ticks;
  };
}
