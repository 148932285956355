import React, { createContext, useState, ReactNode, useEffect } from "react";
import axios from "../api/axios-wrapper";
import axiosDefault from "axios";
import eventEmitter, { EVENTS } from "../emitter/eventEmitter";
import { useNavigate } from "react-router-dom";
import { APPROUTES } from "../appRoutes";

interface AuthContextType {
  authToken: { token: string; refreshToken: string } | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  isLoggedIn: boolean;
  refreshTokens: () => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthProviderProps {
  children: ReactNode;
}

const apiUrl = "auth/login";
const refreshUrl = "auth/refresh";

const STORAGE_KEYS = {
  TOKEN: "token",
  REFRESH_TOKEN: "refreshToken",
  TOKEN_EXPIRY: "tokenExpiry",
} as const;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuth] = useState<{
    token: string;
    refreshToken: string;
  } | null>(null);
  const navigate = useNavigate();

  const checkLogOut = () => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
    const tokenExpiry = localStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRY);

    if (token && refreshToken && tokenExpiry) {
      const now = new Date().getTime();
      if (now < parseInt(tokenExpiry)) {
        setAuth({ token, refreshToken });
      } else {
        // Token expired, try to refresh
        refreshTokens().catch(() => {
          // If refresh fails, clear storage and log out
          clearAuthStorage();
          setAuth(null);
        });
      }
    } else {
      setAuth(null);
    }
  };

  const clearAuthStorage = () => {
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.TOKEN_EXPIRY);
  };

  const isLoggedIn = authToken !== null;

  useEffect(() => {
    eventEmitter.on(EVENTS.LOGOUT, logout);
    checkLogOut();
    return () => {
      eventEmitter.off(EVENTS.LOGOUT, logout);
    };
  }, []);

  const refreshTokens = async (): Promise<boolean> => {
    try {
      const currentToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
      const currentRefreshToken = localStorage.getItem(
        STORAGE_KEYS.REFRESH_TOKEN
      );

      if (!currentToken || !currentRefreshToken) {
        return false;
      }

      const response = await axios.post(refreshUrl, {
        token: currentToken,
        refreshToken: currentRefreshToken,
      });

      if (response.data.token && response.data.refreshToken) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);

        localStorage.setItem(STORAGE_KEYS.TOKEN, response.data.token);
        localStorage.setItem(
          STORAGE_KEYS.REFRESH_TOKEN,
          response.data.refreshToken
        );
        localStorage.setItem(
          STORAGE_KEYS.TOKEN_EXPIRY,
          expiryDate.getTime().toString()
        );

        setAuth({
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        });

        return true;
      }

      return false;
    } catch (error) {
      console.error("Token refresh failed:", error);
      return false;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(apiUrl, { email, password });
      if (response.data.token && response.data.refreshToken) {
        // Set token expiry to 30 days from now
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);

        localStorage.setItem(STORAGE_KEYS.TOKEN, response.data.token);
        localStorage.setItem(
          STORAGE_KEYS.REFRESH_TOKEN,
          response.data.refreshToken
        );
        localStorage.setItem(
          STORAGE_KEYS.TOKEN_EXPIRY,
          expiryDate.getTime().toString()
        );

        setAuth({
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        });
      }
    } catch (error) {
      if (
        axiosDefault.isAxiosError(error) &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        throw new Error("Invalid credentials, access forbidden.");
      } else {
        throw new Error("An error occurred during login.");
      }
    }
  };

  const logout = () => {
    clearAuthStorage();
    setAuth(null);
    navigate(APPROUTES.APP.ROOT);
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, authToken, login, logout, refreshTokens }}
    >
      {children}
    </AuthContext.Provider>
  );
};
