import { create } from "zustand";
import { StockData } from "../../../WebChart/src/interfaces";
import { TimeFrame } from "../models/layoutModels";

type ChartData = {
  items: Record<TimeFrame, StockData[]>; // String key for dynamic timeframes
  addItem: (timeFrame: TimeFrame, item: StockData) => void;
};

// Zustand store
export const useChartData = create<ChartData>((set) => ({
  items: {} as Record<TimeFrame, StockData[]>, // Initialize as an empty object
  addItem: (timeFrame, item) =>
    set((state) => ({
      items: {
        ...state.items,
        [timeFrame]: state.items[timeFrame]
          ? [...state.items[timeFrame], item]
          : [item],
      },
    })),
}));
