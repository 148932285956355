import { Dev_ScanApi } from "../api_dev/scan-api";
import { USE_LOCAL } from "../app";
import { ScanItem, Scans } from "../scanner/scan-interfaces";
import axios from "./axios-wrapper";

const apiUrl = "/scan";

export interface IScanApi {
  getScans(): Promise<Scans | undefined>;
  createScan(scanItem: ScanItem): Promise<void>;
  patchScan(scanItem: ScanItem): Promise<void>;
}

export const GetScanApi = (): IScanApi => {
  if (USE_LOCAL) {
    return Dev_ScanApi;
  }

  return ScanApi;
};

export const ScanApi: IScanApi = {
  getScans: async (): Promise<Scans | undefined> => {
    try {
      const response = await axios.get<Scans>(apiUrl);

      if (response.status === 200) {
        // Set the retrieved data to the state
        return response.data;
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
  createScan: async (scanItem: ScanItem): Promise<void> => {
    axios
      .post(apiUrl, scanItem)
      .then((response) => {
        console.log("Success:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  patchScan: async (scanItem: ScanItem): Promise<void> => {
    try {
      const resp = await axios.patch(apiUrl, scanItem);
      return resp.data;
    } catch (error) {
      console.error("Error:", error);
    }
  },
};
