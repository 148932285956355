import { create } from "zustand";
import { GetLayoutApi } from "../api/layout-api";
import { LayoutItem } from "../models/layoutModels";

type SubscriptionLevel = "Free" | "Paid";

type appData = {
  ActiveSymbol: string | null;
  ActiveWindow: string | null;
  SetActiveWindow: (window: string | null) => void;
  SetActiveSymbol: (symbol: string) => void;
  ActiveLayout: LayoutItem | null;
  SetActiveLayout: (layout: LayoutItem) => void;
  UpsertLayout: (layout: LayoutItem, persist: boolean) => void;
  SubscriptionLevel: () => SubscriptionLevel;
};

export const useAppStore = create<appData>((set) => ({
  ActiveSymbol: null,
  ActiveWindow: null,
  SetActiveWindow: (val) => {
    set((state) => ({ ...state, ActiveWindow: val }));
  },
  ActiveLayout: null,
  SubscriptionLevel: () => {
    return "Free";
  },
  SetActiveSymbol: (val) => set((state) => ({ ...state, ActiveSymbol: val })),
  SetActiveLayout: (val) => set((state) => ({ ...state, ActiveLayout: val })),
  UpsertLayout: async (layoutItem: LayoutItem, persist: boolean) => {
    try {
      if (persist) {
        if (!layoutItem.id) {
          const res = await GetLayoutApi().addLayout(layoutItem);
          layoutItem.id = res;
        } else {
          const res = await GetLayoutApi().patchLayout(layoutItem);
        }
      }

      set((state) => ({
        ...state,
        ActiveLayout: layoutItem,
      }));
    } catch (error: any) {
      // Handle error, you can also throw it so it can be caught by the caller
      console.error("Update failed", error);
      throw error;
    }
  },
}));
