import { IFormulaApi } from "../api/formula-api";
import { FormulaItem, Formulas } from "../formula/formula-interfaces";

export const Dev_FormulaApi: IFormulaApi = {
  getFormulas: function (): Promise<Formulas | undefined> {
    return Promise.resolve({
      "36a29484-4e14-43e7-b809-fe792a1e71dd": {
        Id: "36a29484-4e14-43e7-b809-fe792a1e71dd",
        Name: "TI42",
        Description: "Intensity of the trend ",
        Formula: "100*avgc4/avgc42",
        Type: "User",
      },
    });
  },
  createFormulaItem: function (
    formulaItem: FormulaItem
  ): Promise<string | undefined> {
    throw new Error("Function not implemented.");
  },
};
