import React from "react";
import "./loadingindicator.scss"; // Import your CSS file for styling

export const LoadingIndicator = ({ isLoading }: { isLoading: boolean }) => {
  // If isLoading is true, render the loading indicator
  return isLoading ? (
    <div className="loading-container">
      <div className="spinner"></div>
    </div>
  ) : null;
};
