import { IModule, ModuleProps } from "../../calculator/Modules";
import { IDataTypeHelper } from "../../helpers/DataHelpers";
import { PointTypes } from "../../interfaces/PointTypes";

export class SimpleMovingAverage implements IModule {
  private dataHelper: IDataTypeHelper<PointTypes>;

  constructor(
    props: ModuleProps,
    period: number,
    color: string,
    dataHelper: IDataTypeHelper<PointTypes>
  ) {
    this.props = props;
    this.period = period;
    this.color = color;
    this.dataHelper = dataHelper;
  }
  private props: ModuleProps;
  private MAs: any[] = [];
  private period: number;
  private color: string;

  //TODO: calculate this with addition.
  render = () => {
    const stockData = this.props.dataMan.GetData();
    this.calculateMovingAverage(stockData, this.period);

    const path = new Path2D();
    this.props.ctx.save();
    this.props.ctx.strokeStyle = this.color;

    for (
      let i = this.props.xHelper.minDataIndex;
      i <= this.props.xHelper.maxDataIndex;
      i++
    ) {
      var pos = this.MAs[i];
      if (!pos) {
        continue;
      }
      const x =
        this.props.xHelper.XIndexToPixel(i) +
        this.props.xHelper.ElementAndSpaceWidth() / 2;
      path.lineTo(x, pos);
    }
    this.props.ctx.stroke(path);
    this.props.ctx.restore();
  };

  calculateMovingAverage = (
    stockData: PointTypes[] | PointTypes[][],
    period: number
  ): void => {
    // Ensure there's enough data to calculate the moving average
    if (stockData.length < period) {
      console.warn("not enough data for MA");
    }

    if (Array.isArray(stockData[0])) {
      console.log("TODO calc for two");
      return;
    }
    for (let i = 0; i <= stockData.length - period; i++) {
      let sum = 0;
      for (let j = i; j < i + period; j++) {
        sum += this.dataHelper.getCalcValue(stockData[j] as PointTypes);
      }
      let average = sum / period;
      this.MAs[i + period] = this.props.yHelper.NumYToPixel(average);
    }
  };
}
