import { IScanApi } from "../api/scan-api";
import { ScanItem, Scans } from "../scanner/scan-interfaces";

export const Dev_ScanApi: IScanApi = {
  getScans: function (): Promise<Scans | undefined> {
    return Promise.resolve({
      "2953f204-268b-4405-917a-64b2f2ecfac8": {
        Universe: [3, 6, 12],
        Formulas: [
          {
            FormulaId: "7f5acf16-6c45-466d-992a-9e976f7f0c92",
            Evaluation: "6.5",
          },
          {
            FormulaId: "36a29484-4e14-43e7-b809-fe792a1e71dd",
            Evaluation: "4",
          },
          { FormulaId: "36a29484-4e14-43e7-b809-fe792a1e71dd", Evaluation: "" },
        ],
        Id: "2953f204-268b-4405-917a-64b2f2ecfac8",
        Name: "Test Scan",
        Description: "some random description",
      },
    });
  },
  createScan: function (scanItem: ScanItem): Promise<void> {
    throw new Error("Function not implemented.");
  },
  patchScan: function (scanItem: ScanItem): Promise<void> {
    throw new Error("Function not implemented.");
  },
};
