import { Area } from "../Area";
import { ChartConfig } from "../Chart";
import { XHelpers } from "../XHelpers";
import { IModule } from "../calculator/Modules";
import { ResetCanvas } from "../helpers/CanvasHelper";
import { CallbackManager, MouseManager } from "../managers";
import { CrosshairRenderer, DrawnLineRenderer, MenuRenderer } from ".";

export class OverlayRenderer implements IModule {
  private modules: IModule[] = [];
  private ctx: CanvasRenderingContext2D;

  constructor(
    xHelper: XHelpers,
    areas: Area[],
    menu: MenuRenderer,
    ctx: CanvasRenderingContext2D,
    config: ChartConfig,
    callbackMan: CallbackManager,
    mouseMan: MouseManager
  ) {
    this.ctx = ctx;
  }

  addModule = (module: IModule) => this.modules.push(module);

  calculate = () => void {};

  public render = (): void => {
    ResetCanvas(this.ctx);
    this.modules.forEach((f) => {
      f.render();
    });
  };
}
