import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios-wrapper";
import { APPROUTES } from "../appRoutes";
import { AuthModal } from "./authModal";

export const ConfirmEmail = () => {
  const location = useLocation();
  const [confirmationStatus, setConfirmationStatus] = useState<
    "pending" | "success" | "error"
  >("pending");

  const navigate = useNavigate();

  useEffect(() => {
    const confirmEamil = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      const userId = queryParams.get("userId");

      if (token) {
        // Call the API to verify the token
        try {
          await axios.post("auth/confirmemail", { token, userId });
          setConfirmationStatus("success");
        } catch (error) {
          setConfirmationStatus("error");
        }
      }
    };

    confirmEamil();
  }, [location]);

  return (
    <AuthModal>
      {confirmationStatus === "pending" && <h2>Confirming your email...</h2>}
      {confirmationStatus === "success" && (
        <div>
          <h2>Email confirmed successfully!</h2>
          <Button
            variant="outlined"
            onClick={() => navigate(APPROUTES.APP.LOGIN)}
          >
            Login
          </Button>
        </div>
      )}
      {confirmationStatus === "error" && (
        <div>
          <h2>Failed to confirm email. Please try again.</h2>
          <Button
            variant="outlined"
            onClick={() => navigate(APPROUTES.APP.ROOT)}
          >
            Close
          </Button>
        </div>
      )}
    </AuthModal>
  );
};
