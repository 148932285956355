import { create } from "zustand";
import { GetScanApi } from "../api/scan-api";
import { ScanItem, Scans } from "../scanner/scan-interfaces";

interface useScanStore {
  ScanEditingId: string;
  SetScanEditingId: (num: string) => void;
  GetScans: Scans;
  UpdateScan: (scanItem: ScanItem) => Promise<void>;
  SetScans: (scans: Scans) => void;
  GetScanById: (id: string) => ScanItem;
}

export const useScanStore = create<useScanStore>((set, get) => ({
  ScanEditingId: "",
  SetScanEditingId: (num: string) =>
    set(() => ({
      ScanEditingId: num,
    })),
  GetScans: {},
  UpdateScan: (scanItem: ScanItem) => {
    return GetScanApi()
      .patchScan(scanItem)
      .then((f) => {
        set((state) => ({
          GetScans: {
            ...state.GetScans,
            [scanItem.Id as string]: scanItem, // Overwrite existing items with the new one
          },
        }));
      })
      .catch((error: any) => {
        // Handle error, you can also throw it so it can be caught by the caller
        console.error("Update failed", error);
        throw error;
      });
  },
  SetScans: (scans: Scans) => {
    set(() => ({
      GetScans: scans,
    }));
  },
  GetScanById: (id: string) => {
    return get().GetScans[id];
  },
}));
