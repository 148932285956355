import { Area } from "../Area";
import { XHelpers } from "../XHelpers";
import { IModule } from "../calculator/Modules";
import { IMouseEvents, LineData, MenuActions, Point } from "../interfaces";
import { CallbackManager, MouseManager } from "../managers";
import { ContextMenu, MenuRenderer } from "./MenuRenderer";

//todo: drawn line to main
//in process line to overlay

export class DrawnLineRenderer implements IModule, IMouseEvents {
  constructor(
    xHelper: XHelpers,
    area: Area[],
    ctx: CanvasRenderingContext2D,
    mouse: MouseManager,
    menu: MenuRenderer,
    callBackMan: CallbackManager
  ) {
    this.ctx = ctx;
    this.xHelper = xHelper;
    this.areas = area;
    this.mouseMan = mouse;
    this.menu = menu;
    this.callBackMan = callBackMan;
    this.activeLine = null;

    this.isDrawingLine = false;
    this.MousePosOffset = { x: 0, y: 0 };
    this.linePos = { x: 0, y: 0 };
    this.lines = [];
  }

  private ctx: CanvasRenderingContext2D;
  private xHelper: XHelpers;
  private areas: Area[];
  private mouseMan: MouseManager;
  private menu: MenuRenderer;
  private callBackMan: CallbackManager;
  private isDrawingLine: boolean;
  private mouseTimer: null | ReturnType<typeof setTimeout> = null;
  private MousePosOffset: Point;
  private linePos: Point | null;
  private lines: LineData[];
  public activeLine: LineData | null;

  setLines = (lines: LineData[]) => {
    this.lines = lines;
  };

  clearInProgressMouseAction = () => {
    this.linePos = null;
    if (this.mouseTimer != null) {
      clearTimeout(this.mouseTimer);
    }
    this.isDrawingLine = false;
  };

  onMouseUp(event: MouseEvent): void {
    if (this.isDrawingLine && this.linePos != null) {
      const mouseOffset = this.MousePosOffset;
      const min = this.xHelper.minDataIndex;
      const start = this.xHelper.PixelToIndex(this.linePos.x) + min;
      const end = this.xHelper.PixelToIndex(mouseOffset.x) + min;

      if (start < 0 || end < 0) {
        this.clearInProgressMouseAction();
        return;
      }

      var yHelper = this.areas[0].yHelper;
      let ld: LineData = {
        startTime: this.xHelper.keys[start],
        startPrice: yHelper.PixelYToNum(this.linePos.y),
        endTime: this.xHelper.keys[end],
        endPrice: yHelper.PixelYToNum(mouseOffset.y),
      };

      this.callBackMan.callCallbacks("LineDrawn", ld);
    }
    this.clearInProgressMouseAction();
  }

  onMouseDown(event: MouseEvent): void {
    const action = this.menu.selectedMenuAction;

    if (action == MenuActions.DrawLine) {
      if (this.mouseTimer) {
        clearTimeout(this.mouseTimer);
      }
      this.mouseTimer = setTimeout(() => {
        this.onClickHold();
      }, 200);
      this.linePos = this.MousePosOffset;
    }
  }

  onMouseMove(event: MouseEvent): void {
    this.MousePosOffset = {
      x: event.offsetX,
      y: event.offsetY,
    };
  }

  destroy?: (() => void) | undefined;

  onClickHold = () => {
    if (!this.mouseMan.isScalingPrice) {
      this.isDrawingLine = true;
    }
  };

  calculate = () => {
    return;
  };

  private drawingLine = () => {
    if (this.linePos) {
      const ctx = this.ctx;

      //solid
      ctx.beginPath();
      ctx.moveTo(this.linePos.x, this.linePos.y);
      ctx.lineTo(
        this.mouseMan.MousePosClient.x,
        this.mouseMan.MousePosClient.y
      );
      ctx.stroke();
    }
  };

  pointLineHitTest(point: Point, lineTo: Point, lineFrom: Point): boolean {
    // Calculate the distance between the point (x, y) and the line segment (x1, y1) to (x2, y2)
    var withOffset: Point = {
      x: point.x, //- this.winManager.chartOffsetX,
      y: point.y,
    };

    const d1 = this.distance(withOffset, lineTo);
    const d2 = this.distance(withOffset, lineFrom);
    const lineLength = this.distance(lineTo, lineFrom);

    // Set a small threshold value to account for floating-point precision issues
    const threshold = 0.1;

    // Check if the sum of distances d1 and d2 is approximately equal to the line length
    return (
      d1 + d2 >= lineLength - threshold && d1 + d2 <= lineLength + threshold
    );
  }

  distance(point1: Point, point2: Point) {
    // Calculate the Euclidean distance between two points
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  render = () => {
    if (
      this.menu.selectedMenuAction == MenuActions.DrawLine &&
      this.isDrawingLine
    ) {
      this.drawingLine();
    }

    const middleOfElement: number = this.xHelper.ElementAndSpaceWidth() / 2;
    this.activeLine = null;

    if (!this.lines) {
      return;
    }
    //TODO this only works for primary area.
    const yHelper = this.areas[0].yHelper;

    this.menu.SetMenu("main");

    for (const line of this.lines) {
      const x1 = this.xHelper.TimeToPosition(line.startTime) + middleOfElement;
      const x2 = this.xHelper.TimeToPosition(line.endTime) + middleOfElement;

      if (
        !this.xHelper.IsXOnGraphArea(x1) &&
        !this.xHelper.IsXOnGraphArea(x2)
      ) {
        return;
      }

      const y1 = yHelper.NumYToPixel(line.startPrice);
      const y2 = yHelper.NumYToPixel(line.endPrice);

      //recalc our positions
      const pos1: Point = {
        x: x1,
        y: y1,
      };
      const pos2: Point = {
        x: x2,
        y: y2,
      };

      const ctx = this.ctx;
      //solid
      ctx.save();
      ctx.beginPath();
      if (this.pointLineHitTest(this.mouseMan.MousePosClient, pos1, pos2)) {
        this.activeLine = line;
        ctx.strokeStyle = "red";
        this.menu.SetMenu("drawing", this.drawingMenuItems);
      }

      ctx.moveTo(pos1.x, pos1.y);
      ctx.lineTo(pos2.x, pos2.y);
      ctx.stroke();

      ctx.restore();
    }
  };

  public readonly drawingMenuItems: ContextMenu[] = [
    {
      action: "delete (d)",
      shortCut: "d",
      event: () => {
        this.callBackMan.callCallbacks("LineRemoved", this.activeLine);
      },
    },
  ];
}
