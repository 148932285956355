import AddIcon from "@mui/icons-material/Add";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";

import "./menu-header.scss";
import { Menu } from "@mui/icons-material";
import { ContextMenu } from "../menus";
import { useAppStore } from "../store";
import { LayoutContainer, LayoutItem } from "../models/layoutModels";
import { MenuItem } from "@mui/material";
import {
  formatDateToMMDD_HHMMSS,
  getMinDate,
} from "../../../WebChart/src/helpers";

export interface menuProps {
  containerId: string;
  remove: () => void;
  settings?: () => void;
  add?: () => void;
  refresh?: () => void;
  display?: React.JSX.Element | null;
  type: "watchList" | "scan" | "chart";
  lastUpdate?: Date;
}

export const MenuHeader = ({
  containerId,
  remove,
  settings,
  add,
  refresh,
  display,
  type,
  lastUpdate,
}: menuProps) => {
  const elementRef = useRef<HTMLSpanElement>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [ActiveLayout, UpsertLayout] = useAppStore(
    useShallow((s) => [s.ActiveLayout, s.UpsertLayout])
  );

  useEffect(() => {
    if (showContextMenu && elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      setPosition({
        y: rect.top + 10,
        x: rect.left + 10,
      });
    }
  }, [showContextMenu]);

  const renderDynamicRight = () => {
    if (settings !== undefined) {
      return (
        <SettingsIcon
          fontSize="inherit"
          className="settings-btn"
          onClick={settings}
        />
      );
    }
  };

  const renderDynamicLeft = (): React.JSX.Element[] => {
    var eles: React.JSX.Element[] = [];
    if (display) {
      eles.push(display);
    }
    if (refresh !== undefined) {
      eles.push(
        <RefreshIcon
          key="refresh_icon"
          fontSize="inherit"
          className="refresh-btn btn-hover"
          onClick={refresh}
        />
      );
    }
    if (add !== undefined) {
      eles.push(
        <AddIcon
          key="header_add_icon"
          fontSize="inherit"
          className="add-btn btn-hover"
          onClick={add}
        />
      );
    }

    return eles;
  };

  const handleIntervalChange = (event: any) => {
    var val = event.target.value;

    var layout = ActiveLayout as LayoutItem;
    var container = layout.containers.find(
      (f) => f.i === containerId
    ) as LayoutContainer;
    if (container.props) {
      container.props = { ...container.props, timeFrame: val };
    }
    // UpsertLayout(layout);
  };

  const handleAutoRefreshChange = (isChecked: boolean) => {
    var layout = ActiveLayout as LayoutItem;
    var container = layout.containers.find(
      (f) => f.i === containerId
    ) as LayoutContainer;
    if (container.props) {
      container.props = { ...container.props, autoRefresh: isChecked };
    }
    //  UpsertLayout(layout);
  };

  var selectedProps = ActiveLayout?.containers?.find(
    (f) => f.i === containerId
  )?.props;

  return (
    <div className="parent-container" style={{ fontSize: "14px" }}>
      <div className="child">
        {renderDynamicLeft()}{" "}
        {lastUpdate && lastUpdate.getTime() !== getMinDate().getTime() && (
          <span style={{ fontSize: "12px" }}>
            last update: {formatDateToMMDD_HHMMSS(lastUpdate)}
          </span>
        )}
      </div>
      <div
        style={{ paddingTop: "2px", paddingRight: "2px", gap: "2px" }}
        className="child flex-end"
      >
        {renderDynamicRight()}
        <span ref={elementRef}>
          <Menu fontSize="inherit" onClick={() => setShowContextMenu(true)} />
        </span>
        <span>
          <OpenWithIcon
            fontSize="inherit"
            className="drag-handle draggable-handle btn-hover"
          />
        </span>
        <ContextMenu
          open={showContextMenu}
          position={position}
          onClose={() => setShowContextMenu(false)}
        >
          <MenuItem onClick={remove}>Remove</MenuItem>
          {/* <li>
                <FormControlLabel
                  style={{
                    margin: 0, // Remove margins
                    fontSize: "0.625rem", // Smaller font size for the label
                  }}
                  value="bottom"
                  control={
                    <Checkbox
                      size="small" // Keeps the checkbox small
                      style={{
                        padding: "0.5px", // Reduce padding for a smaller checkbox
                        transform: "scale(0.75)", // Scale down the checkbox
                      }}
                      checked={selectedProps?.autoRefresh || false}
                      onChange={(event: BaseSyntheticEvent) =>
                        handleAutoRefreshChange(event.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 12 }}>Auto-refresh</Typography>
                  }
                  labelPlacement="end"
                />
              </li> */}
          {/* {type === "chart" && (
                <li>
                  <Picker
                    options={[
                      { label: "1day", value: "1day" },
                      { label: "1min", value: "1min" },
                    ]}
                    selectedValue={selectedProps?.timeFrame || "1day"}
                    handleChange={handleIntervalChange}
                  />
                </li>
              )} */}
        </ContextMenu>
      </div>
    </div>
  );
};
