import React, { useContext } from "react";
import { createRoot } from "react-dom/client";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { StyledEngineProvider } from "@mui/material/styles";
import App from "./app";
import { AppExtended } from "./appExtended";
import { AuthProvider, AuthContext } from "./auth/authContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ForgotPassword, Login, Register, ResetPassword } from "./auth";
import { APPROUTES } from "./appRoutes";
import { ConfirmEmail } from "./auth/confirmEmail";
import { CreateWatchlist } from "./menus";
import { MustLogin } from "./auth/authModal";
import { addContainer } from "./helpers/layoutHelper";
import { LogOut } from "./auth/logout";
import { AddToWatchListModal } from "./Lists";
import { WebHome } from "./home/WebHome";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript

//import { MockSignalRService } from "./signalr/dev_signalr_service";

//TODO: real time updates
//SignalRService.init();
//MockSignalRService.init();

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={APPROUTES.HOME} element={<WebHome />} />
      <Route
        path={APPROUTES.APP.ROOT}
        element={
          <AuthProvider>
            <BodyWrapper />
          </AuthProvider>
        }
      >
        <Route path={APPROUTES.APP.LOGIN} element={<Login />} />
        <Route path={APPROUTES.APP.LOGOUT} element={<LogOut />} />

        <Route path={APPROUTES.APP.REGISTER} element={<Register />} />
        <Route path={APPROUTES.APP.CONFIRM_EMAIL} element={<ConfirmEmail />} />

        <Route
          path={APPROUTES.APP.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          path={APPROUTES.APP.RESET_PASSWORD}
          element={<ResetPassword />}
        />
        <Route
          path={APPROUTES.APP.ADD_WATCHLIST_TICKER}
          element={<AddToWatchListModal />}
        />
        <Route path={APPROUTES.APP.MUST_LOGIN} element={<MustLogin />} />
        <Route
          path={APPROUTES.APP.ADD_WATCHLIST}
          element={
            <CreateWatchlist
              handleCreated={(newId) => {
                addContainer(newId, "watchList");
              }}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export const BodyWrapper = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext must be used within an AuthProvider");
  }

  const { authToken } = authContext;
  return (
    <>
      {!authToken?.token && <App />}
      {authToken?.token && <AppExtended />}
    </>
  );
};

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>
);
