import NativeSelect from "@mui/material/NativeSelect";
import React, { ReactElement } from "react";
import { generateRandomAlphaNumeric } from "./random-generator";

export interface PickerOption {
  value: string;
  label: string;
}

export interface PickerProps {
  handleChange: (event: any) => void;
  options: PickerOption[];
  selectedValue: string;
  emptyOption?: boolean;
}

const EMPTY_KEY = "empt_key010";

export const Picker = ({
  options,
  handleChange,
  selectedValue,
  emptyOption = false,
}: PickerProps) => {
  const eleOptions = () => {
    var opts: ReactElement[] = [];
    var rand: string = generateRandomAlphaNumeric(5);
    emptyOption && opts.push(<option key={EMPTY_KEY} value=""></option>);
    options.forEach((f, index) => {
      opts.push(
        <option
          // selected={f.value === selectedValue}
          key={rand + index}
          value={f.value}
        >
          {f.label}
        </option>
      );
    });
    return opts;
  };

  const listOptions = eleOptions();

  return (
    <NativeSelect
      onChange={handleChange}
      value={selectedValue}
      inputProps={{
        name: "age",
        id: "uncontrolled-native",
      }}
      sx={{
        fontSize: "0.7rem",
        "& .MuiNativeSelect-standard": {
          padding: "2px",
          paddingRight: "14px !important",
        },

        "&::before": {
          border: "0px",
        },
        "& .MuiNativeSelect-icon": {
          top: "calc(50% - 0.4em)",
          width: ".75em",
          height: ".75em",
        },
      }}
    >
      {listOptions}
    </NativeSelect>
  );
};
