import { EventEmitter } from "events";

const eventEmitter = new EventEmitter();

export default eventEmitter;

export const EVENTS = {
  LOGOUT: "Logout",
  LOGGEDOUT: "LoggedOut",
};
