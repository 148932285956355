import { Slide, SlideProps } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import * as React from "react";
import { useSnackStore } from "../store";
import { useShallow } from "zustand/react/shallow";

export const SnackBar = () => {
  const [isSnackOpen, SnackMessage, Close, severity] = useSnackStore(
    useShallow((s) => [s.isSnackOpen, s.SnackMessage, s.Close, s.severity])
  );

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    Close();
  };

  return (
    <Snackbar
      key="snack-bar"
      open={isSnackOpen}
      autoHideDuration={4500}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {SnackMessage}
      </Alert>
    </Snackbar>
  );
};
